/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import FilterPanel from "../modules/Reports/WalletBalanceReports/FilterPanel"
import {
  getCustomersList
} from "../modules/Customers/_axios/customerCrud"
import { injectIntl } from "react-intl"
import ExportButton from "../modules/Reports/ExportButton"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import { getWalletBalanceReports } from "../modules/Reports/_axios/reportsCrud"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import WalletBalanceList from "../modules/Reports/WalletBalanceReports/WalletBalanceList"

function WalletBalanceReportsPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false
  })
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [customerData, setCustomerData] = useState({
    customers: [],
    isLoading: true
  })

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function fetchReports(cancelToken) {
    const { customer, department } = searchParams
    const params = {
      customer: customer.CustomerID,
      department
    }

    props.history.push({
      pathname: "/reports-wallet-balance",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getWalletBalanceReports(
      customer.CustomerID,
      department || undefined,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: data,
          isLoading: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function fetchCustomers(cancelToken) {
    getCustomersList(cancelToken.token, true)
      .then(({ data }) => {
        setCustomerData({ customers: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!customerData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const department = urlParams.get("department") || ""
      const customer = urlParams.get("customer")
        ? customerData.customers.find(
            customer => customer.CustomerID === urlParams.get("customer")
          )
        : {}
      setSearchParams({
        department,
        customer
      })
    }
  }, [customerData])

  useEffect(() => {
    if (
      searchParams.customer?.CustomerID
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      const departmentName = searchParams.department
        ? intl.formatMessage({
            id: "EXPORT_FILE_DEPARTMENT"
          }) +
          "_" +
          searchParams.customer.Departments.find(
            el => el.DepartmentID === searchParams.department
          ).Name +
          "_"
        : ""

      setExportFileName(
        intl.formatMessage({
          id: "EXPORT_FILE_NAME.WALLET_BALANCE_REPORT"
        }) +
          "_" +
          intl.formatMessage({
            id: "EXPORT_FILE_CUSTOMER"
          }) +
          "_" +
          searchParams.customer.Name +
          "_" +
          departmentName
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  function getExportData() {
    const balance = reportsData.reports.reduce((sum, item) => sum += item.balance, 0)
    const response = reportsData.reports.map(report => {
      const formattedReport = {}
      formattedReport[
        intl.formatMessage({
          id: "REPORT.FULL_NAME"
        })
      ] = report.fullName
      formattedReport[
        intl.formatMessage({
          id: "REPORT.DEPARTMENT_NAME"
        })
      ] = report.departmentName
      formattedReport[
        intl.formatMessage({
          id: "REPORT.EXTERNAL_ID"
        })
      ] = report.externalID
      formattedReport[
        intl.formatMessage({
          id: "REPORT.BALANCE"
        })
      ] = report.balance
      return formattedReport
    })
    const formattedReport = {}
    formattedReport[
        intl.formatMessage({
          id: "REPORT.FULL_NAME"
        })
        ] = intl.formatMessage({
          id: "REPORT.TOTAL"
        })
    formattedReport[
        intl.formatMessage({
          id: "REPORT.BALANCE"
        })
        ] = balance
    response.push(formattedReport)
    return response;
  }

  return (
    <>
      {searchParams.customer && (
        <FilterPanel
          itemsData={reportsData.reports}
          onSearchParamsSave={handleSearchParamsSave}
          customerData={customerData}
          initialSearchParams={searchParams}
        />
      )}
      {reportsData.reports.length !== 0 && (
          <div className="text-right">
            <ExportButton
                exportData={getExportData()}
                fileName={exportFileName}
            />
          </div>
      )}
      <WalletBalanceList
        reportsData={reportsData}
      />
    </>
  )
}

export default withRouter(
  injectIntl(
    connect(null, snackbarRedux.actions)(WalletBalanceReportsPage)
  )
)
