/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import FilterPanel from "../modules/Reports/CustomerStoreReports/FilterPanel"
import { getCustomersList } from "../modules/Customers/_axios/customerCrud"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import { getCustomerStoreReports } from "../modules/Reports/_axios/reportsCrud"
import ExportButton from "../modules/Reports/ExportButton"
import StoreReportsList from "../modules/Reports/CustomerStoreReports/StoreReportsList"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"

function CustomerStoreReportsPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false,
    notRequested: true
  })
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [customerData, setCustomerData] = useState({
    customers: [],
    isLoading: true
  })

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function fetchReports(cancelToken) {
    const { customer, location, from, to } = searchParams

    const params = {
      from: formatDateForApi(from),
      to: formatDateForApi(to)
    }
    if (customer.CustomerID) {
      params.customer = customer.CustomerID
      if (location) {
        params.location = location
      }
    }

    props.history.push({
      pathname: "/reports-store-customer",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getCustomerStoreReports(
      customer.CustomerID,
      location || null,
      params.from,
      params.to,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function fetchCustomers(cancelToken) {
    getCustomersList(cancelToken.token, true)
      .then(({ data }) => {
        setCustomerData({ customers: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!customerData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const from = urlParams.get("from")
        ? new Date(urlParams.get("from"))
        : new Date()
      const to = urlParams.get("to")
        ? new Date(urlParams.get("to"))
        : new Date()
      const location = urlParams.get("location") || ""
      const customer = urlParams.get("customer")
        ? customerData.customers.find(
            customer => customer.CustomerID === urlParams.get("customer")
          )
        : {}
      setSearchParams({
        from,
        to,
        location,
        customer
      })
    }
  }, [customerData])

  useEffect(() => {
    if (searchParams.location && searchParams.from && searchParams.to) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      const locationName =
        intl.formatMessage({
          id: "EXPORT_FILE_LOCATION"
        }) +
        "_" +
        searchParams.customer.Locations.find(
          el => el.LocationID === searchParams.location
        ).Name +
        "_"

      const customerName =
        intl.formatMessage({
          id: "EXPORT_FILE_CUSTOMER"
        }) +
        "_" +
        searchParams.customer.Name +
        "_"

      setExportFileName(
        intl.formatMessage({
          id: "EXPORT_FILE_NAME.CUSTOMER_STORE_REPORT"
        }) +
          "_" +
          customerName +
          locationName +
          moment(searchParams.from).format("DD-MM-YYYY") +
          "_-_" +
          moment(searchParams.to).format("DD-MM-YYYY")
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  function getExportData() {

    const quantity = reportsData.reports.reduce((s, t) => s + t.Quantity, 0)
    const refoundQuantity = reportsData.reports.reduce((s, t) => s + t.RefoundQuantity, 0)
    const netQuantity = reportsData.reports.reduce((s, t) => s + t.NetQuantity, 0)
    const customerPrice = reportsData.reports.reduce((s, t) => s + t.CustomerPrice, 0)
    const costPrice = reportsData.reports.reduce((s, t) => s + t.CostPrice, 0)
    const totalCost = reportsData.reports.reduce((s, t) => s + t.TotalCost, 0)

    const items = reportsData.reports.map(report => {
      const formattedReport = {}
      formattedReport[
        intl.formatMessage({
          id: "REPORT.ITEM_NAME"
        })
      ] = report.ItemName
      formattedReport[
        intl.formatMessage({
          id: "REPORT.QUANTITY"
        })
      ] = report.Quantity
      formattedReport[
        intl.formatMessage({
          id: "REPORT.REFOUND_QUANTITY"
        })
      ] = report.RefoundQuantity

      formattedReport[
        intl.formatMessage({
          id: "REPORT.NET_QUANTITY"
        })
      ] = report.NetQuantity
      formattedReport[
        intl.formatMessage({
          id: "REPORT.CUSTOMER_PRICE"
        })
      ] = report.CustomerPrice
      formattedReport[
        intl.formatMessage({
          id: "REPORT.COST_PRICE"
        })
      ] = report.CostPrice
      formattedReport[
        intl.formatMessage({
          id: "REPORT.TOTAL_COST"
        })
      ] = report.TotalCost
      return formattedReport
    })


    const formattedReport = {}
    formattedReport[
        intl.formatMessage({
          id: "REPORT.ITEM_NAME"
        })
        ] = intl.formatMessage({
      id: "REPORT.TOTAL"
    })
    formattedReport[
        intl.formatMessage({
          id: "REPORT.QUANTITY"
        })
        ] = quantity
    formattedReport[
        intl.formatMessage({
          id: "REPORT.REFOUND_QUANTITY"
        })
        ] = refoundQuantity

    formattedReport[
        intl.formatMessage({
          id: "REPORT.NET_QUANTITY"
        })
        ] = netQuantity
    formattedReport[
        intl.formatMessage({
          id: "REPORT.CUSTOMER_PRICE"
        })
        ] = customerPrice
    formattedReport[
        intl.formatMessage({
          id: "REPORT.COST_PRICE"
        })
        ] = costPrice
    formattedReport[
        intl.formatMessage({
          id: "REPORT.TOTAL_COST"
        })
        ] = totalCost


    items.push(formattedReport)
    return items;
  }

  return (
    <>
      {searchParams.customer && (
        <FilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          customerData={customerData}
          initialSearchParams={searchParams}
        />
      )}
      {reportsData.reports.length !== 0 && (
          <div className="text-right">
            <ExportButton
                exportData={getExportData()}
                fileName={exportFileName}
            />
          </div>
      )}
      <StoreReportsList
        reports={reportsData.reports}
        isLoading={reportsData.isLoading}
        showCustomerPrice={true}
      />

    </>
  )
}
export default withRouter(
  injectIntl(connect(null, snackbarRedux.actions)(CustomerStoreReportsPage))
)
