/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import FilterPanel from "../modules/Reports/EmployeeReports/FilterPanel"
import {
  getCustomer,
  getCustomersList
} from "../modules/Customers/_axios/customerCrud"
import ReportsList from "../modules/Reports/EmployeeReports/ReportsList"
import { injectIntl } from "react-intl"
import moment from "moment"
import ExportButton from "../modules/Reports/ExportButton"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import { getEmployeeReports , getEmployeesOrdersCSV } from "../modules/Reports/_axios/reportsCrud"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"

function EmployeeReportsPage({ userRole, userCustomerInfo, intl, ...props }) {
  const dispatch = useDispatch()
  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false
  })
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [customerData, setCustomerData] = useState({
    customers: [],
    isLoading: true
  })

  const isUserAdmin = userRole === "Admin" || userRole === "Owner"

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function fetchReports(cancelToken) {
    const { customer, location, department, from, to } = searchParams
    const params = {
      from: formatDateForApi(from),
      to: formatDateForApi(to),
      customer: customer.CustomerID,
      location,
      department
    }

    props.history.push({
      pathname: "/reports-employee",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getEmployeeReports(
      customer.CustomerID,
      department || undefined,
      location || undefined,
      params.from,
      params.to,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: data,
          isLoading: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function downloadEmployeesOrdersCSVRequest(){
    setIsLoadingFile(true);
    const cancelToken = axios.CancelToken.source()
    const { customer, location, department, from, to } = searchParams
    const params = {
      from: formatDateForApi(from),
      to: formatDateForApi(to),
      customer: customer.CustomerID,
      location,
      department
    }
    getEmployeesOrdersCSV(      searchParams.customer.CustomerID,
      department || undefined,
      location || undefined,
      params.from,
      params.to,
      cancelToken.token)
      .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'report.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
      })
      .finally(() => {
        setIsLoadingFile(false);
      });
  }

  function fetchCustomers(cancelToken) {
    if (!isUserAdmin) {
      // Request for Customer Admin
      getCustomer(cancelToken.token, userCustomerInfo.CustomerID, false)
        .then(({ data }) => {
          setCustomerData({ customers: [data], isLoading: false })
        })
        .catch(error =>
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: "API.ERROR.FAILED_TO_GET_CUSTOMER"
            })
          )
        )
    } else {
      // Request for Admin
      getCustomersList(cancelToken.token, true)
        .then(({ data }) => {
          setCustomerData({ customers: data, isLoading: false })
        })
        .catch(error =>
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
            })
          )
        )
    }
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!customerData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const from = urlParams.get("from")
        ? new Date(urlParams.get("from"))
        : new Date()
      const to = urlParams.get("to")
        ? new Date(urlParams.get("to"))
        : new Date()
      const location = urlParams.get("location") || ""
      const department = urlParams.get("department") || ""
      const customer = isUserAdmin
        ? urlParams.get("customer")
          ? customerData.customers.find(
              customer => customer.CustomerID === urlParams.get("customer")
            )
          : {}
        : customerData.customers[0] || {}
      setSearchParams({
        from,
        to,
        location,
        department,
        customer
      })
    }
  }, [customerData])

  useEffect(() => {
    if (
      searchParams.from &&
      searchParams.to &&
      searchParams.customer.CustomerID
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      const locationName = searchParams.location
        ? intl.formatMessage({
            id: "EXPORT_FILE_LOCATION"
          }) +
          "_" +
          searchParams.customer.Locations.find(
            el => el.LocationID === searchParams.location
          ).Name +
          "_"
        : ""

      const departmentName = searchParams.department
        ? intl.formatMessage({
            id: "EXPORT_FILE_DEPARTMENT"
          }) +
          "_" +
          searchParams.customer.Departments.find(
            el => el.DepartmentID === searchParams.department
          ).Name +
          "_"
        : ""

      setExportFileName(
        intl.formatMessage({
          id: "EXPORT_FILE_NAME"
        }) +
          "_" +
          intl.formatMessage({
            id: "EXPORT_FILE_CUSTOMER"
          }) +
          "_" +
          searchParams.customer.Name +
          "_" +
          locationName +
          departmentName +
          moment(searchParams.from).format("DD-MM-YYYY") +
          "_-_" +
          moment(searchParams.to).format("DD-MM-YYYY")
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  function getExportData() {
    const rows = reportsData.reports;
    const breakfast = rows.reduce((sum, it) => sum + it.Breakfast,  0)
    const luanch = rows.reduce((sum, it) => sum + it.Luanch,  0)
    const dinner = rows.reduce((sum, it) => sum + it.Dinner,  0)

    const result =  reportsData.reports.map(report => {
      const formattedReport = {}
      formattedReport[
        intl.formatMessage({
          id: "EXPORT_FILE_NAME.EMPLOYEE_REPORT"
        })
      ] = report.FullName + ((report.CreatorName &&
          report.CreatorName !== report.FullName) ? '(' + report.CreatorName + ')' : '')
      formattedReport[
          intl.formatMessage({
            id: "REPORT.EXTERNAL_ID"
          })
          ] = report.ExternalID
      formattedReport[
        intl.formatMessage({
            id: "REPORT.MOBILE"
        })
        ] = report.Mobile
        formattedReport[
            intl.formatMessage({
                id: "REPORT.EMAIL"
            })
            ] = report.Email
        formattedReport[
        intl.formatMessage({
          id: "REPORT.DEPARTMENT_NAME"
        })
      ] = report.DepartmentName
      formattedReport[
        intl.formatMessage({
          id: "REPORT.EMAIL"
        })
      ] = report.Email
      formattedReport[
        intl.formatMessage({
          id: "REPORT.MOBILE"
        })
      ] = report.Mobile
      formattedReport[
        intl.formatMessage({
          id: "REPORT.BREAKFAST"
        })
      ] = report.Breakfast
      formattedReport[
        intl.formatMessage({
          id: "REPORT.LUNCH"
        })
      ] = report.Luanch
      formattedReport[
        intl.formatMessage({
          id: "REPORT.DINNER"
        })
      ] = report.Dinner
      return formattedReport
    });
    // Summary
    const formattedReport = {}
    formattedReport[
        intl.formatMessage({
          id: "EXPORT_FILE_NAME.EMPLOYEE_REPORT"
        })
        ] = intl.formatMessage({
      id: "REPORT.TOTAL"
    })
    formattedReport[
        intl.formatMessage({
          id: "REPORT.BREAKFAST"
        })
        ] = breakfast
    formattedReport[
        intl.formatMessage({
          id: "REPORT.LUNCH"
        })
        ] = luanch
    formattedReport[
        intl.formatMessage({
          id: "REPORT.DINNER"
        })
        ] = dinner
    result.push(formattedReport)
    return result;
  }

  return (
    <>
      {searchParams.customer && (
        <FilterPanel
          itemsData={reportsData.reports}
          showCustomerField={isUserAdmin}
          onSearchParamsSave={handleSearchParamsSave}
          customerData={customerData}
          initialSearchParams={searchParams}
          isLoadingFile={isLoadingFile}
          downloadEmployeesOrdersCSV = {downloadEmployeesOrdersCSVRequest}
        />
      )}
      {reportsData.reports.length !== 0 && (
          <div className="text-right">
            <ExportButton
                exportData={getExportData()}
                fileName={exportFileName}
            />
          </div>
      )}
      <ReportsList
        reportsData={reportsData}
        fromDate={searchParams.from}
        toDate={searchParams.to}
      />
    </>
  )
}

function mapStateToProps(state) {
  return {
    userRole: state.auth.user.Role,
    userCustomerInfo: state.auth.user.Customer
  }
}
export default withRouter(
  injectIntl(
    connect(mapStateToProps, snackbarRedux.actions)(EmployeeReportsPage)
  )
)
