/* eslint-disable no-restricted-imports */
import { getMealTypeText } from "../modules/Common/mealTypesTranslation"
import { makeStyles, Typography } from "@material-ui/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import ItemList from "../modules/Refund/ItemList"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import { withRouter } from "react-router"
import FilterPanel from "../modules/Refund/FilterPanel"
import RefundFormDialog from "../modules/Refund/RefundFormDialog"
import { getCustomersList } from "../modules/Customers/_axios/customerCrud"
import { getSuppliers } from "../modules/Suppliers/_axios/supplierCrud"
import renderRefundType from "../modules/Common/customer/customer-function"
import {
  getRefundItems,
  sendCompanyCreditRefund,
  sendCreditCardRefund,
  sendRefund
} from "../modules/Refund/_axios/refundCrud"
import CreditCardRefundFormDialog from "../modules/Refund/CreditCardRefundFormDialog"
import { formatDateForApi,formatDate } from "../modules/Common/momentFunctions"
import DishLoader from "../modules/Common/DishLoader"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import ExportButton from "../modules/Reports/ExportButton"

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    width: "100%",
    minHeight: 400
  }
}))

function RefundAdminPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [itemsData, setItemsData] = useState({
    itemsCount: 0,
    displayedData: [],
    isLoading: true,
    notRequested: true
  })
  const [customerData, setCustomerData] = useState({
    customers: [],
    isLoading: true
  })
  const [supplierData, setSupplierData] = useState({
    suppliers: [],
    isLoading: true
  })

  const [searchParams, setSearchParams] = useState({})
  const [paginationState, setPaginationState] = useState()

  const [refundFormPanelData, setRefundFormPanelData] = useState({
    show: false,
    orderItemId: null,
    subsidyAmount: null
  })
  const [
    creditCardRefundFormPanelData,
    setCreditCardRefundFormPanelData
  ] = useState({
    show: false,
    orderItemId: null,
    employeeAmount: null,
    type: null
  })

  function handlePaginationStateChange(changes) {
    const newState = { ...paginationState, ...changes }
    setPaginationState(newState)
  }

  function getParams() {
    const { date, customer, location, supplier, username } = searchParams
    const { page, rowsPerPage } = paginationState

    const params = {
      date: formatDateForApi(date),
      offset: page,
      limit: rowsPerPage,
      username: username
    }

    if (customer.CustomerID) {
      params.customerid = customer.CustomerID
      if (location) {
        params.locationid = location
      }
    } else if (supplier.SupplierID) {
      params.supplierid = supplier.SupplierID
    }
    return params;
  }

  function fetchItemHistory(cancelToken) {
    const params = getParams();

    props.history.push({
      pathname: "/refund",
      search: "?" + new URLSearchParams(params).toString()
    })

    setItemsData({ ...itemsData, isLoading: true })
    getRefundItems(
      {
        Date: params.date,
        Offset: params.offset,
        Limit: params.limit,
        CustomerId: params.customerid,
        LocationId: params.locationid,
        SupplierId: params.supplierid,
        UserName: params.username
      },
      cancelToken.token
    )
      .then(({ data }) => {
        setItemsData({
          itemsCount: data.total,
          displayedData: data.data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_REFUND_ITEMS"
          })
        )
      )
  }

  function _generalUpdateItems(
    { SubsidyRefund, EmployeeRefundSum, RefundType, RefundReason, orderItemId },
    creditCardRefundFormPanelData
  ) {
    const items = [...itemsData.displayedData]
    const changedItem = items.find(
      item => item.OrderItemID === creditCardRefundFormPanelData.orderItemId
    )
    if (EmployeeRefundSum) {
      changedItem.EmployeeRefundAmount = EmployeeRefundSum
    } else {
      changedItem.RefundDate = formatDateForApi(new Date())
      changedItem.SubsidyRefundAmount = SubsidyRefund
      changedItem.RefundType = RefundType
    }
    changedItem.RefundReason = RefundReason
    setItemsData({
      ...itemsData,
      displayedData: items,
      isLoading: false,
      notRequested: false
    })
  }

  function updateItemsForCredit(obj) {
    _generalUpdateItems(obj, creditCardRefundFormPanelData)
  }
  function updateItems(obj) {
    _generalUpdateItems(obj, refundFormPanelData)
  }

  function fetchCustomers(cancelToken) {
    getCustomersList(cancelToken.token, true)
      .then(({ data }) => {
        setCustomerData({ customers: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
          })
        )
      )
  }

  function fetchSuppliers(cancelToken) {
    getSuppliers(cancelToken.token)
      .then(({ data }) => {
        setSupplierData({ suppliers: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    fetchSuppliers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!customerData.isLoading && !supplierData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const rowsPerPageValue = urlParams.get("limit")
      const rowsPerPage = rowsPerPageValue ? +rowsPerPageValue : 50
      const pageValue = urlParams.get("offset")
      const page = pageValue ? +pageValue : 0
      const sortBy = urlParams.get("sortBy") || "FirstName"
      const orderBy = urlParams.get("orderBy") || "asc"

      const username = urlParams.get("username") || ""
      const date = urlParams.get("date")
        ? new Date(urlParams.get("date"))
        : new Date()
      const customer =
        customerData.customers.find(
          customer => customer.CustomerID === urlParams.get("customerid")
        ) || {}
      const location = urlParams.get("locationid") || ""
      const supplier =
        supplierData.suppliers.find(
          supplier => supplier.SupplierID === urlParams.get("supplierid")
        ) || {}

      setSearchParams({
        date,
        customer,
        location,
        supplier,
        username
      })

      setPaginationState({
        page,
        rowsPerPage,
        orderBy,
        sortBy
      })
    }
  }, [customerData, supplierData])

  useEffect(() => {
    getHistory();
  }, [searchParams, paginationState])

  function getHistory() {
    if (searchParams.date && paginationState.rowsPerPage) {
      const cancelToken = axios.CancelToken.source()
      fetchItemHistory(cancelToken)
      return () => cancelToken.cancel()
    }
  }

  function refundExportDataParser(data) {
    const items = data.map(item => {
      const object = {};
      object[ intl.formatMessage({
        id: "TABLE.HEAD_LABELS.ITEM_NAME"
      })]=item.ItemName
      object[ intl.formatMessage({
        id: "TABLE.HEAD_LABELS.EMPLOYEE_NAME"
      })]=item.UserName
      object[ intl.formatMessage({
        id: "TABLE.HEAD_LABELS.MEAL_TYPE"
      })]=getMealTypeText(intl, item.MealType)
      object[ intl.formatMessage({
        id: "TABLE.HEAD_LABELS.RESTAURANT"
      })]=item.SupplierName
      object[ intl.formatMessage({
        id: "TABLE.HEAD_LABELS.CUSTOMER"
      })]=item.CustomerName
      object[ intl.formatMessage({
        id: "TABLE.HEAD_LABELS.ORDER_NUMBER"
      })]=item.OrderNumber
      object[ intl.formatMessage({
        id: "TABLE.HEAD_LABELS.EMPLOYEE_AMOUNT"
      })]=item.EmploeeAmount
      object[ intl.formatMessage({
        id: "TABLE.HEAD_LABELS.SUBSIDY_AMOUNT"
      })]=item.SubsidyAmount
      object[ intl.formatMessage({
        id: "TABLE.HEAD_LABELS.TOTAL_COST"
      })]=item.TotalCost
      object[ intl.formatMessage({
        id: "TABLE.HEAD_LABELS.REFUND_AMOUNT"
      })] = item.SubsidyRefundAmount + item.WalletRefundAmount + item.CostumerCreditRefundAmount + item.CreditCardRefundAmount + item.BudgetRefundAmount
      object[ intl.formatMessage({
        id: "TABLE.HEAD_LABELS.REFUND"
      })]=renderRefundType(item.RefundType, intl)
      return object;
    })
    return items;
  }

  function handleExport(response) {
    const params = getParams();
    const cancelToken = axios.CancelToken.source()
    getRefundItems(
        {
          Date: params.date,
          CustomerId: params.customerid,
          LocationId: params.locationid,
          SupplierId: params.supplierid,
          UserName: params.username
        },
        cancelToken.token
    )
        .then(({ data }) => {
          response(refundExportDataParser(data.data), `${intl.formatMessage({
            id: "EXPORT_FILE_NAME.REPORT"
          })}_${formatDate(new Date())}`)
        })
        .catch(error =>
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_GET_REFUND_ITEMS"
                })
            )
        )
  }

  return (
    <>
      <RefundFormDialog
        show={refundFormPanelData.show}
        onClose={() =>
          setRefundFormPanelData({ show: false, orderItemId: null })
        }
        submitData={data => sendRefund(refundFormPanelData.orderItemId, data)}
        updateItems={updateItems}
        subsidyAmount={refundFormPanelData.subsidyAmount}
        data={refundFormPanelData.data}
        onExtraAction={() => {
          getHistory();
        }}
      />
      <CreditCardRefundFormDialog
        show={creditCardRefundFormPanelData.show}
        onClose={() =>
          setCreditCardRefundFormPanelData({ show: false, orderItemId: null })
        }
        submitData={data =>
          creditCardRefundFormPanelData.type === 2
            ? sendCompanyCreditRefund(
                creditCardRefundFormPanelData.orderItemId,
                { Sum: data.EmployeeRefundSum, RefundReason: data.RefundReason }
              )
            : sendCreditCardRefund(
                creditCardRefundFormPanelData.orderItemId,
                data
              )
        }
        type={creditCardRefundFormPanelData.type}
        updateItems={updateItemsForCredit}
        employeeAmount={creditCardRefundFormPanelData.employeeAmount}
      />
      {searchParams.customer && searchParams.supplier && (
        <FilterPanel
          onSearchParamsSave={searchParams => setSearchParams(searchParams)}
          customerData={customerData}
          supplierData={supplierData}
          initialSearchParams={searchParams}
        />
      )}
      <div className={classes.wrapper}>
        {itemsData.isLoading ? (
          <DishLoader centered />
        ) : itemsData.itemsCount !== 0 ? (
            <>
              <div className="d-inline">
                <ExportButton exportData={itemsData.displayedData}
                              fileName={`${intl.formatMessage({
                                id: "EXPORT_FILE_NAME.REPORT"
                              })}_${formatDate(new Date())}`}
                              onHandleData={handleExport}
                              isLoading={itemsData.isLoading}/>
              </div>
              <ItemList
                  paginationState={paginationState}
                  onPageChange={handlePaginationStateChange}
                  itemsData={itemsData}
                  openRefundDialog={(orderItemId, subsidyAmount, data) => {
                    setRefundFormPanelData({
                      show: true,
                      orderItemId,
                      subsidyAmount,
                      data
                    })
                  }}
                  openCreditCardRefundDialog={(orderItemId, employeeAmount, type) =>
                      setCreditCardRefundFormPanelData({
                        show: true,
                        orderItemId,
                        employeeAmount,
                        type
                      })
                  }
              />
            </>
        ) : itemsData.notRequested ? null : (
          <Typography variant="h4" className={classes.heading}>
            <FormattedMessage id="COMMON.NO_RESULTS" />
          </Typography>
        )}
      </div>
    </>
  )
}

export default injectIntl(
  withRouter(connect(null, snackbarRedux.actions)(RefundAdminPage))
)
