/* eslint-disable no-restricted-imports */
import React, {useEffect, useState} from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import FilledInput from "@material-ui/core/FilledInput"
import InputLabel from "@material-ui/core/InputLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Select from "@material-ui/core/Select"
import {useFormik} from "formik"
import * as Yup from "yup"
import {FormattedMessage, injectIntl} from "react-intl"
import {FormControlLabel} from "@material-ui/core"
import {useStyles} from "../Common/_styles/formDialogStyles"
import {actions as snackbarActions} from "../../../redux/snackbar/snackbarRedux"
import {useDispatch} from "react-redux"
import {handleApiError} from "../../../redux/snackbar/snackbarHandlers"
import {specialDateTypes, getSpecialDateTypeID, specialDateTypesMapping, specialDateTypesMappingReverted} from "../../modules/SpecialDates/specialDateTypeTranslation"
import DateFnsUtils from "@date-io/date-fns";
import {he} from "date-fns/esm/locale";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";


function CreateSpecialDateFormDialog({
                                         show,
                                         initialSpecialDateState,
                                         closeDialog,
                                         submitData,
                                         submitIcon,
                                         specialDateTypes,
                                         specialDatesData,
                                         customerID,
                                         supplierID,
                                         headerTextId,
                                         intl
                                     }) {
    const dispatch = useDispatch()
    const classes = useStyles()
    let initialValues
    if (initialSpecialDateState.SpecialDateID) {
        initialValues = {
            name: initialSpecialDateState.Name,
            date: initialSpecialDateState.Date,
            specialDateType: specialDateTypesMappingReverted[initialSpecialDateState.SpecialDateType],
        }
    } else {
        initialValues = {
            name: "",
            date: "",
            specialDateType: "",
        }
    }

    function handleSumbit(data) {
        closeDialog(data)
    }

    function handleClose() {
        closeDialog(null)
    }

    const nameValidationSchema = Yup.string()
        .test(
            "nameIsUnique",
            intl.formatMessage({
                id: "VALIDATION.NAME_USED"
            }),
            value => {
                const specialDate = specialDatesData.specialDates.find(specialDate => specialDate.Name === value)
                return specialDate === undefined || specialDate.SpecialDateID === initialSpecialDateState.SpecialDateID
            }
        )
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )

    const priorityValidationSchema = Yup.number()
        .min(0, intl.formatMessage({id: "VALIDATION.MIN_VALUE"}))
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )


    const validationSchema = Yup.object().shape(
        {
            name: nameValidationSchema,
        },
        ["name"]
    )

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const todayLabel = new Date();

    const today = (todayLabel.getMonth() + 1) + '/' +
        todayLabel.getDate() + '/' +
        todayLabel.getFullYear();
    const formik = useFormik({
        initialValues,
        // validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting, resetForm}) => {
            setSubmitting(true)
            const {name, date, specialDateType} = values
            const tokenData = {}
            tokenData.Name = name
            tokenData.Date = date || today
            tokenData.SpecialDateType = specialDateTypesMapping[specialDateType]
            tokenData.SpecialDateID = initialSpecialDateState.SpecialDateID
            tokenData.CustomerID = customerID || null
            tokenData.SupplierID = supplierID || null

            submitData({
                tokenData
            })
                .then(({data}) => {
                    if (initialSpecialDateState.SpecialDateID) {
                        dispatch(
                            snackbarActions.setSnackbarData(
                                intl.formatMessage({
                                    id: "API.EDIT_SUCCESS"
                                })
                            )
                        )
                        handleSumbit({SpecialDateID: initialSpecialDateState.SpecialDateID, ...tokenData})
                    } else {
                        dispatch(
                            snackbarActions.setSnackbarData(
                                intl.formatMessage({
                                    id: "API.CREATE_SUCCESS"
                                })
                            )
                        )
                        setSubmitting(false)
                        tokenData.SpecialDateID = data.SpecialDateID
                        tokenData.Date = convert(tokenData.Date)
                        handleSumbit({SpecialDateID: data.SpecialDateID, ...tokenData})

                    }
                })
                .catch(error => {
                    setSubmitting(false)
                    handleApiError(
                        dispatch,
                        error,
                        intl.formatMessage({
                            id: initialSpecialDateState.SpecialDateID
                                ? "API.ERROR.FAILED_TO_UPDATE_TAG"
                                : "API.ERROR.FAILED_TO_CREATE_TAG"
                        })
                    )
                })
        }
    })

    function checkForError(fieldName) {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true
        }
        return false
    }

    function renderErrors(fieldName) {
        return checkForError(fieldName) ? (
            <span className={classes.error}>{formik.errors[fieldName]}</span>
        ) : null
    }

    return (
        <div>
            <Dialog
                open={show}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" disableTypography={true}>
                    <h3 style={{fontWeight: "bold", textAlign: "center"}}>
                        <FormattedMessage id={headerTextId && headerTextId !== "" ? headerTextId : "CREATE_FORM.TITLE"} />
                    </h3>
                </DialogTitle>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <DialogContent className={classes.smallForm}>
                        <div className="position-relative">
                            <TextField
                                id="name"
                                name="name"
                                label={intl.formatMessage({
                                    id: "CREATE_FORM.NAME_FIELD.LABEL"
                                })}
                                className={classes.textField}
                                margin="normal"
                                variant="filled"
                                fullWidth
                                {...formik.getFieldProps("name")}
                                error={checkForError("name")}
                            />
                            {renderErrors("name")}
                        </div>

                        <div className="position-relative">
                            <FormControl
                                variant="filled"
                                className={classes.textField}
                                fullWidth
                                error={checkForError("specialDateType")}
                            >
                                <InputLabel htmlFor="specialDateType-select">
                                    <FormattedMessage id="CREATE_FORM.TYPE.LABEL"/>
                                </InputLabel>
                                <Select
                                    {...formik.getFieldProps("specialDateType")}
                                    input={<FilledInput name="specialDateType" id="specialDateType-select"/>}
                                >
                                    {specialDateTypes.map(specialDateType => (
                                        <MenuItem key={specialDateType} value={specialDateType}>
                                            <FormattedMessage id={getSpecialDateTypeID(specialDateType)}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {renderErrors("name")}
                        </div>
                        <div className="position-relative">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
                                <DatePicker
                                    autoOk
                                    format="dd/MM/yyyy"
                                    value={formik.values.date}
                                    onChange={value => {
                                        formik.setFieldValue("date", value)
                                    }}
                                    label={intl.formatMessage({
                                        id: "DATE_FIELD.LABEL"
                                    })}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            type="button"
                            size="large"
                            onClick={handleClose}
                            className={classes.button}
                        >
                            <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON"/>
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            color="secondary"
                            className={classes.button}
                            disabled={formik.isSubmitting}
                        >
                            <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON"/>
                            {formik.isSubmitting && (
                                <span className="ml-1 spinner spinner-white"></span>
                            )}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default injectIntl(CreateSpecialDateFormDialog)
