/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import Paper from "@material-ui/core/Paper"
import { withRouter } from "react-router-dom"
import ItemCancellationRangeFilterPanel from "../modules/Reports/ItemCancellationReports/ItemCancellationRangeFilterPanel"
import ItemList from "../modules/Reports/ItemCancellationReports/ItemCancellationList"
import {
  getLocations,
  getSuppliers
} from "../modules/Suppliers/_axios/supplierCrud"

import {
  getCustomersList
} from "../modules/Customers/_axios/customerCrud"
import ExportButton from "../modules/Reports/ExportButton"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import { getMealTypeText } from "../modules/Common/mealTypesTranslation"
import { getCancelledItemOrderReports } from "../modules/Reports/_axios/reportsCrud"
import { useStyles } from "../modules/Common/_styles/listWrapperStyles"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import DishLoader from "../modules/Common/DishLoader"
import TableCell from "@material-ui/core/TableCell";
import { formatDate } from "../modules/Common/momentFunctions"

function ItemCancellationOrdersReportsPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false,
    notRequested: true
  })
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [supplierData, setSupplierData] = useState({
    data: [],
    isLoading: true
  })
  const [customerData, setCustomerData] = useState({
    data: [],
    isLoading: true
  })
  const [locationsData, setLocationsData] = useState({
    data: [],
    isLoading: true,
    selectedSupplierID: null,
    notRequested: true
  })

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function fetchReports(cancelToken) {
    const { supplier, location, from, to, customer } = searchParams

    const params = {
      from: formatDateForApi(from),
      to: formatDateForApi(to),
      location,
      supplier: supplier?.SupplierID ?? '',
      customer: customer?.CustomerID ?? ''
    }

    props.history.push({
      pathname: "/reports-cancelled-item-orders",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getCancelledItemOrderReports(
      supplier?.SupplierID ?? null,
      customer?.CustomerID ?? null,
      params.from,
      params.to,
      location || null,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: data.Data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function fetchSuppliers(cancelToken) {
    getSuppliers(cancelToken.token)
      .then(({ data }) => {
        setSupplierData({ data: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
          })
        )
      )
  }

  function fetchCustomers(cancelToken) {
    getCustomersList(cancelToken.token, false)
        .then(({ data }) => {
          setCustomerData({ data: data, isLoading: false })
        })
        .catch(error =>
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
                })
            )
        )
  }

  function fetchLocations(cancelToken) {
    setLocationsData({ ...locationsData, isLoading: true })
    getLocations(locationsData.selectedSupplierID, cancelToken.token)
      .then(({ data }) => {
        setLocationsData({
          data: data,
          isLoading: false,
          selectedSupplierID: locationsData.selectedSupplierID,
          notRequested: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_LOCATIONS"
          })
        )
      )
  }

  function handleUpdateLocations(supplierID) {
    setLocationsData({
      ...locationsData,
      selectedSupplierID: supplierID
    })
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchSuppliers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!supplierData.isLoading && !customerData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const from = urlParams.get("from")
        ? new Date(urlParams.get("from"))
        : new Date()
      const to = urlParams.get("to")
        ? new Date(urlParams.get("to"))
        : new Date()
      const location = urlParams.get("location") || ""
      const supplier = urlParams.get("supplier")
        ? supplierData.data.find(
            supplier => supplier.SupplierID === urlParams.get("supplier")
          )
        : {}

      const customer = urlParams.get("customer")
          ? customerData.data.find(
              customer => customer.CustomerID === urlParams.get("customer")
          ) || {}
          : {}
      setSearchParams({
        from,
        to,
        location,
        supplier,
        customer
      })
    }
  }, [supplierData, customerData])

  useEffect(() => {
    if (
      searchParams.from &&
      searchParams.to
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      const locationName = searchParams.location
        ? intl.formatMessage({
            id: "EXPORT_FILE_LOCATION"
          }) +
          "_" +
          locationsData.data.find(
            location => location.LocationID === searchParams.location
          ).Name +
          "_"
        : ""

      setExportFileName(
        intl.formatMessage({
          id: "EXPORT_FILE_NAME"
        }) +
          "_" +
          moment(searchParams.from).format("DD-MM-YYYY") +
          "_-_" +
          moment(searchParams.to).format("DD-MM-YYYY")
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams, locationsData.notRequested])

  useEffect(() => {
    if (locationsData.selectedSupplierID) {
      const cancelToken = axios.CancelToken.source()
      fetchLocations(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [locationsData.selectedSupplierID])

  function getExportData() {
    console.log(reportsData)
    const formattedReport = []
    if (reportsData.reports) {
      reportsData.reports.forEach(item => {
        const formattedItem = {}
        formattedItem[
            intl.formatMessage({
              id: "REPORT.ITEM_NAME"
            })
            ] = item.ItemName
        formattedItem[
            intl.formatMessage({
              id: "REPORT.SUPPLIER_NAME"
            })
            ] = item.SupplierName
        formattedItem[
            intl.formatMessage({
              id: "REPORT.CUSTOMER_NAME"
            })
            ] = item.CustomerName
        formattedItem[
            intl.formatMessage({
              id: "REPORT.EMPLOYEE_NAME"
            })
            ] = item.EmployeeName
        formattedItem[
            intl.formatMessage({
              id: "REPORT.MOBILE"
            })
            ] = item.Mobile
        formattedItem[
            intl.formatMessage({
              id: "REPORT.EMAIL"
            })
            ] = item.Email
        formattedItem[
            intl.formatMessage({
              id: "REPORT.LOCATION_NAME"
            })
            ] = item.LocationName
        formattedItem[
            intl.formatMessage({
              id: "REPORT.MEAL_TYPE"
            })
            ] = getMealTypeText(intl, item.MealType)
        formattedItem[
            intl.formatMessage({
              id: "REPORT.ORDER_TO_DATE"
            })
            ] = formatDate(item.OrderToDate)
          formattedItem[
              intl.formatMessage({
                  id: "REPORT.STATUS_UPDATE_USER_INFO"
              })
              ] = `${item.UserInfo?.FirstName} ${item.UserInfo?.LastName} ${item.UserInfo?.Email} ${item.UserInfo?.Mobile}`
        formattedReport.push(formattedItem)
      })
    }
    return formattedReport
  }

  return (
    <>
      {searchParams.supplier && searchParams.customer && (
        <ItemCancellationRangeFilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          supplierData={supplierData}
          customerData={customerData}
          locationsData={locationsData}
          initialSearchParams={searchParams}
          updateLocations={handleUpdateLocations}
          isLocationMandatory={true}
        />
      )}
      {
        reportsData.isLoading && (
            <DishLoader/>
          )
      }
      {
          !reportsData.isLoading && (
              <>
                {reportsData.reports.length !== 0 && (
                    <div className="text-right">
                      <ExportButton
                          exportData={getExportData()}
                          fileName={exportFileName}
                      />
                    </div>
                )}
                <ItemList reportsData={reportsData} intl={intl}/>
              </>
          )
      }
    </>
  )
}
export default withRouter(
  injectIntl(connect(null, snackbarRedux.actions)(ItemCancellationOrdersReportsPage))
)
