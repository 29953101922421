/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import ListsWrapper from "../modules/Reports/ListsWrapper"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import { getCustomerDetailReports } from "../modules/Reports/_axios/reportsCrud"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import OrderItemsList from "../modules/Reports/CustomerDetailsReports/OrderItemsList"
import OrderItemsSummary from "../modules/Reports/CustomerDetailsReports/OrderItemsSummary"
import { useStyles } from "../modules/Common/_styles/listWrapperStyles"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { getCustomersList } from "../modules/Customers/_axios/customerCrud"
import CustomerLocationDateFilterPanel from "../modules/Reports/CustomerDetailsReports/CustomerLocationDateFilterPanel"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"

function CustomerDetailsReportsPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false,
    notRequested: true
  })
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [customerData, setCustomerData] = useState({
    data: [],
    isLoading: true
  })

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function fetchReports(cancelToken) {
    const { customer, location, date } = searchParams

    const params = {
      date: formatDateForApi(date),
      location,
      customer: customer.CustomerID
    }

    props.history.push({
      pathname: "/reports-details-customer",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getCustomerDetailReports(
      customer.CustomerID,
      location,
      params.date,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function fetchCustomers(cancelToken) {
    getCustomersList(cancelToken.token, true)
      .then(({ data }) => {
        setCustomerData({ data: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!customerData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const date = urlParams.get("date")
        ? new Date(urlParams.get("date"))
        : new Date()
      const location = urlParams.get("location") || ""
      const customer = urlParams.get("customer")
        ? customerData.data.find(
            customer => customer.CustomerID === urlParams.get("customer")
          )
        : {}

      setSearchParams({
        date,
        customer,
        location
      })
    }
  }, [customerData])

  useEffect(() => {
    if (
      searchParams.date &&
      searchParams.customer &&
      searchParams.customer.CustomerID
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      setExportFileName(
        intl.formatMessage({
          id: "REPORT.EXPORT_FILE_NAME.CUSTOMER_DETAIL"
        }) +
          "_" +
          intl.formatMessage({
            id: "EXPORT_FILE_CUSTOMER"
          }) +
          "_" +
          searchParams.customer.Name +
          "_" +
          moment(searchParams.date).format("DD-MM-YYYY")
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  function getExportData() {
    const quantity = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.Quantity, 0), 0)

    const formattedReport = []
    reportsData.reports.forEach(report => {
      report.Items.forEach(meal => {
        const formattedMeal = {}
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.SUPPLIER_NAME"
          })
        ] = report.SupplierName
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.ITEM_NAME"
          })
        ] = meal.ItemName
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.QUANTITY"
          })
        ] = meal.Quantity
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.EMPLOYEE_NAMES"
          })
        ] = meal.Orders.map((order, index) => order.UserName).join(", ")
        formattedReport.push(formattedMeal)
      })
    })
    const formattedMeal = {}
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.SUPPLIER_NAME"
        })
        ] = intl.formatMessage({
      id: "REPORT.TOTAL"
    })
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.QUANTITY"
        })
        ] = quantity
    formattedReport.push(formattedMeal)
    return formattedReport
  }

  const totalQuantity = reportsData.reports.reduce((sum, item) => {
    return sum + item.Items.reduce((s, i) => {
      return s + i.Quantity;
    }, 0);
  }, 0)

  return (
    <>
      {searchParams.customer && (
        <CustomerLocationDateFilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          customerData={customerData}
          initialSearchParams={searchParams}
        />
      )}
      <ListsWrapper
        showSummary={true}
        totalQuantity={totalQuantity}
        reportsData={reportsData}
        contents={reportsData.reports.map(supplier => (
          <Paper className={classes.paper} key={supplier.SupplierId}>
            <Typography className={classes.heading}>
              {supplier.SupplierName}
            </Typography>
            <OrderItemsList ordersData={supplier} />
            <OrderItemsSummary ordersData={supplier}/>
          </Paper>
        ))}
        exportData={getExportData()}
        exportFileName={exportFileName}
      />
    </>
  )
}
export default withRouter(
  injectIntl(connect(null, snackbarRedux.actions)(CustomerDetailsReportsPage))
)
