/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import StoreSuppliersList from "../modules/Reports/SupplirStoreReports/StoreSuppliersList"
import { getSupplierStoreReports } from "../modules/Reports/_axios/reportsCrud"
import ExportButton from "../modules/Reports/ExportButton"
import { getSuppliers } from "../modules/Suppliers/_axios/supplierCrud"
import ListsWrapper from "../modules/Reports/ListsWrapper"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { useStyles } from "../modules/Common/_styles/listWrapperStyles"
import SupplierRangeFilterPanel from "../modules/Reports/SupplierRangeFilterPanel"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"

function CustomerStoreReportsPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false,
    notRequested: true
  })
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [supplierData, setSupplierData] = useState({
    data: [],
    isLoading: true
  })

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function fetchReports(cancelToken) {
    const { supplier, from, to } = searchParams

    const params = {
      from: formatDateForApi(from),
      to: formatDateForApi(to)
    }
    params.supplier = supplier.SupplierID

    props.history.push({
      pathname: "/reports-store-supplier",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getSupplierStoreReports(
      supplier.SupplierID,
      params.from,
      params.to,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function fetchSuppliers(cancelToken) {
    getSuppliers(cancelToken.token)
      .then(({ data }) => {
        setSupplierData({ data: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchSuppliers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!supplierData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const from = urlParams.get("from")
        ? new Date(urlParams.get("from"))
        : new Date()
      const to = urlParams.get("to")
        ? new Date(urlParams.get("to"))
        : new Date()
      const supplier = urlParams.get("supplier")
        ? supplierData.data.find(
            supplier => supplier.SupplierID === urlParams.get("supplier")
          )
        : {}
      setSearchParams({
        from,
        to,
        supplier
      })
    }
  }, [supplierData])

  useEffect(() => {
    if (
      searchParams.supplier &&
      searchParams.supplier.SupplierID &&
      searchParams.from &&
      searchParams.to
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      const supplierName =
        intl.formatMessage({
          id: "EXPORT_FILE_SUPPLIER"
        }) +
        "_" +
        searchParams.supplier.Name +
        "_"

      setExportFileName(
        intl.formatMessage({
          id: "EXPORT_FILE_NAME.SUPPLIER_STORE_REPORT"
        }) +
          "_" +
          supplierName +
          moment(searchParams.from).format("DD-MM-YYYY") +
          "_-_" +
          moment(searchParams.to).format("DD-MM-YYYY")
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  function getExportData() {

    const quantity = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.Quantity, 0), 0)
    const supplierPrice = reportsData.reports[0]?.Items[0]?.SupplierPrice || 0;
    const totalRefund = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.TotalRefund, 0), 0)
    const totalSupplierPayment = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.TotalSupplierPayment, 0), 0)


    const formattedItems = []
    reportsData.reports.forEach(report => {
      report.Items.forEach(item => {
        const formattedReport = {}
        formattedReport[
          intl.formatMessage({
            id: "REPORT.LOCATION_NAME"
          })
        ] = item.LocationName
        formattedReport[
          intl.formatMessage({
            id: "REPORT.ITEM_NAME"
          })
        ] = item.ItemName
        formattedReport[
          intl.formatMessage({
            id: "REPORT.TOTAL_QUANTITY"
          })
        ] = item.Quantity
        formattedReport[
          intl.formatMessage({
            id: "REPORT.ITEM_COST"
          })
        ] = item.SupplierPrice
        formattedReport[
          intl.formatMessage({
            id: "REPORT.TOTAL_REFUNDS"
          })
        ] = item.TotalRefund
        formattedReport[
          intl.formatMessage({
            id: "REPORT.TOTAL_SUPPLIER_PAYMENT"
          })
        ] = item.TotalSupplierPayment
        formattedItems.push(formattedReport)
      })
    })

    const formattedReport = {}
    formattedReport[
      intl.formatMessage({
        id: "REPORT.LOCATION_NAME"
      })
      ] = intl.formatMessage({id: "REPORT.TOTAL"})
    formattedReport[
        intl.formatMessage({
          id: "REPORT.ITEM_NAME"
        })
        ] = ''
    formattedReport[
        intl.formatMessage({
          id: "REPORT.TOTAL_QUANTITY"
        })
        ] = quantity
    formattedReport[
        intl.formatMessage({
          id: "REPORT.ITEM_COST"
        })
        ] = supplierPrice
    formattedReport[
        intl.formatMessage({
          id: "REPORT.TOTAL_REFUNDS"
        })
        ] = totalRefund
    formattedReport[
        intl.formatMessage({
          id: "REPORT.TOTAL_COST"
        })
        ] = totalSupplierPayment
    formattedItems.push(formattedReport)

    return formattedItems
  }

  return (
    <>
      {searchParams.supplier && (
        <SupplierRangeFilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          supplierData={supplierData}
          initialSearchParams={searchParams}
        />
      )}
      <ListsWrapper
        reportsData={reportsData}
        contents={reportsData.reports.map(location => (
          <Paper className={classes.paper} key={location.LocationName}>
            <Typography className={classes.heading}>
              {location.LocationName}
            </Typography>
            <StoreSuppliersList reports={location.Items} isLoading={false} />
          </Paper>
        ))}
        exportData={getExportData()}
        exportFileName={exportFileName}
        searchParams={searchParams}
      />
      {reportsData.reports.length !== 0 && (
        <div className="text-right">
          <ExportButton
            exportData={getExportData()}
            fileName={exportFileName}
          />
        </div>
      )}
    </>
  )
}
export default withRouter(
  injectIntl(connect(null, snackbarRedux.actions)(CustomerStoreReportsPage))
)
