/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, {useEffect, useState} from "react"
import {withRouter} from "react-router-dom"
import FilterPanel from "../modules/Reports/BankTransferReports/FilterPanel"
import {
    getCustomersList
} from "../modules/Customers/_axios/customerCrud"
import {FormattedMessage, injectIntl} from "react-intl"
import ExportButton from "../modules/Reports/ExportButton"
import {connect} from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import {getBankBalanceReports, bankTransfer} from "../modules/Reports/_axios/reportsCrud"
import {useDispatch} from "react-redux"
import {handleApiError} from "../../redux/snackbar/snackbarHandlers"
import BankTransferList from "../modules/Reports/BankTransferReports/BankTransferList"
import TransferFormDialog from "../modules/Reports/BankTransferReports/TransferFormDialog"
import {formatDateForApi} from "../modules/Common/momentFunctions"
import {Button} from "@material-ui/core"
import {formatDate} from "../modules/Common/momentFunctions";

function BankTransferReportsPage({intl, ...props}) {
    const dispatch = useDispatch()
    const [reportsData, setReportsData] = useState({
        reports: [],
        selectedReports: [],
        isLoading: false
    })
    const [exportFileName, setExportFileName] = useState("")
    const [showTransferPopup, setShowTransferPopup] = useState(false)
    const [searchParams, setSearchParams] = useState({})
    const [customerData, setCustomerData] = useState({
        customers: [],
        isLoading: true
    })

    function handleSearchParamsSave(changes) {
        const newParams = {...searchParams, ...changes}
        setSearchParams(newParams)
    }

    function fetchReports(cancelToken) {
        const {customer, department, status, from, to, expectedTransferTo, expectedTransferFrom} = searchParams
        const params = {
            customer: customer?.CustomerID || '',
            department: department || '',
            status: status || '',
            from: formatDateForApi(from),
            expectedTransferFrom: formatDateForApi(expectedTransferFrom),
            expectedTransferTo: formatDateForApi(expectedTransferTo),
            to: formatDateForApi(to)
        }

        props.history.push({
            pathname: "/reports-bank-transfer",
            search: "?" + new URLSearchParams(params).toString()
        })

        setReportsData({...reportsData, isLoading: true})
        getBankBalanceReports(
            customer?.CustomerID || undefined,
            department || undefined,
            status || undefined,
            params.from,
            params.to,
            params.expectedTransferFrom,
            params.expectedTransferTo,
            cancelToken.token
        )
            .then(({data}) => {
                setReportsData({
                    reports: data,
                    selectedReports: [],
                    isLoading: false
                })
            })
            .catch(error => handleApiError(dispatch, error))
    }

    function fetchCustomers(cancelToken) {
        getCustomersList(cancelToken.token, true)
            .then(({data}) => {
                setCustomerData({customers: data, isLoading: false})
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
                    })
                )
            )
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        fetchCustomers(cancelToken)
        return () => cancelToken.cancel()
    }, [])

    useEffect(() => {
        if (!customerData.isLoading) {
            const urlParams = new URLSearchParams(window.location.search)
            const department = urlParams.get("department") || ""
            const status = urlParams.get("status") || ""
            const customer = urlParams.get("customer")
                ? customerData.customers.find(
                    customer => customer.CustomerID === urlParams.get("customer")
                )
                : {}
            const from = urlParams.get("from") || new Date()
            const expectedTransferFrom = urlParams.get("expectedTransferFrom") || new Date()
            const to = urlParams.get("to") || new Date()
            const expectedTransferTo = urlParams.get("expectedTransferTo") || new Date()
            setSearchParams({
                department,
                customer,
                status,
                from,
                to,
                expectedTransferFrom,
                expectedTransferTo
            })
        }
    }, [customerData])

    useEffect(() => {
        if (
            searchParams.from && searchParams.to
        ) {
            const cancelToken = axios.CancelToken.source()
            fetchReports(cancelToken)

            const departmentName = searchParams.department
                ? intl.formatMessage({
                    id: "EXPORT_FILE_DEPARTMENT"
                }) +
                "_" +
                searchParams.customer.Departments.find(
                    el => el.DepartmentID === searchParams.department
                ).Name +
                "_"
                : ""

            setExportFileName(
                intl.formatMessage({
                    id: "EXPORT_FILE_NAME.BANK_TRANSFER_REPORT"
                }) +
                "_" +
                intl.formatMessage({
                    id: "EXPORT_FILE_CUSTOMER"
                }) +
                "_" +
                searchParams.customer?.Name +
                "_" +
                (departmentName || '')
            )
            return () => cancelToken.cancel()
        }
    }, [searchParams])

    function getExportData() {
        const balance = reportsData.reports.reduce((sum, item) => sum += (item.amount || 0), 0)
        const response = reportsData.reports.map(report => {
            const formattedReport = {}
            formattedReport[
                intl.formatMessage({
                    id: "REPORT.FULL_NAME"
                })
                ] = report.fullName
            formattedReport[
                intl.formatMessage({
                    id: "REPORT.DEPARTMENT_NAME"
                })
                ] = report.departmentName
            formattedReport[
                intl.formatMessage({
                    id: "REPORT.AMOUNT"
                })
                ] = report.amount
            formattedReport[
                intl.formatMessage({
                    id: "REPORT.TRANSFER_STATUS"
                })
                ] = intl.formatMessage({
                    id: `STATUS.${report.transferStatus}`
                })
            formattedReport[
                intl.formatMessage({
                    id: "REPORT.REQUEST_DATE"
                })
                ] = `${report.requestDate || ''}`


            formattedReport[
                intl.formatMessage({
                    id: "REPORT.EXPECTED_TRANSFER_DATE"
                })
                ] = `${report.expectedTransferDate ? (formatDate(report.expectedTransferDate) || '') : ''}`


            formattedReport[
                intl.formatMessage({
                    id: "REPORT.TRANSFER_DATE"
                })
                ] = `${report.transferDate || ''}`
            formattedReport[
                intl.formatMessage({
                    id: "ADMIN_BANKS.USER_IP"
                })
                ] = `${report.userIP || ''}`
            formattedReport[
                intl.formatMessage({
                    id: "ADMIN_BANKS.TRANSFER_TIME_TYPE"
                })
                ] = report.transferTimeType ? intl.formatMessage({
                    id:`EXPORT_FILE_NAME.TRANSFER_TYPE.${report.transferTimeType || ''}`
                }) : ''

            formattedReport[
                intl.formatMessage({
                    id: "ADMIN_BANKS.USER_DEVICE"
                })
                ] = `${report.userDeviceType || ''}`
            formattedReport[
                intl.formatMessage({
                    id: "ADMIN_BANKS.ACCOUNT_NAME"
                })
                ] = `${report.transferBankAccountName || ''}`
            formattedReport[
                intl.formatMessage({
                    id: "ADMIN_BANKS.ACCOUNT_NUMBER"
                })
                ] = `${report.transferBankAccountNumber || ''}`
            formattedReport[
                intl.formatMessage({
                    id: "ADMIN_BANKS.BANK_NUMBER"
                })
                ] = `${report.transferBankNumber || ''}`
            formattedReport[
                intl.formatMessage({
                    id: "ADMIN_BANKS.BRANCH_NUMBER"
                })
                ] = `${report.transferBankBranchNumber || ''}`
            return formattedReport
        })
        const formattedReport = {}
        formattedReport[
            intl.formatMessage({
                id: "REPORT.FULL_NAME"
            })
            ] = intl.formatMessage({
            id: "REPORT.TOTAL"
        })
        formattedReport[
            intl.formatMessage({
                id: "REPORT.AMOUNT"
            })
            ] = balance
        response.push(formattedReport)
        return response;
    }

    function selectedRows(obj) {
        const {id, selected} = obj;
        const reports = reportsData.selectedReports.slice();
        if (!selected) {
            reports.splice(reports.findIndex(r => r.id === id), 1);
        } else {
            reports.push(reportsData.reports.find(r => r.id === id));
        }
        setReportsData({
            ...reportsData,
            selectedReports: reports
        });
    }

    function handleBankTransfer(ev) {
        return bankTransfer(ev);
    }

    return (
        <>
            {searchParams.customer && (
                <FilterPanel
                    itemsData={reportsData.reports}
                    onSearchParamsSave={handleSearchParamsSave}
                    customerData={customerData}
                    initialSearchParams={searchParams}
                />
            )}
            <div className='btn-container'>
                {reportsData.reports?.length !== 0 && (
                    <div className="text-right">
                        <ExportButton
                            exportData={getExportData()}
                            fileName={exportFileName}
                        />
                    </div>
                )}
                {
                    reportsData.selectedReports?.length !== 0 && (
                        <div className="text-right">
                            <Button
                                id="export_button"
                                type="button"
                                variant="contained"
                                color="secondary"
                                size="large"
                                dir="ltr"
                                className={`font-weight-bold mt-3 mb-3`}
                                onClick={e =>
                                    setShowTransferPopup(true)
                                }
                            >
                                <FormattedMessage id="TRANSFER_DATA"/>
                            </Button>

                            <TransferFormDialog
                                show={showTransferPopup}
                                title={<FormattedMessage id="CREATE_FORM.TITLE"/>}
                                closeDialog={() => {
                                    setShowTransferPopup(false);
                                }}
                                submitData={ev => handleBankTransfer(ev)}
                                afterSubmitCloseDialog={() => {
                                    const cancelToken = axios.CancelToken.source()
                                    fetchReports(cancelToken);
                                }}
                                intl={intl}
                                ids={reportsData.selectedReports.map(it => it.id)}
                            />

                        </div>
                    )
                }
            </div>
            <BankTransferList
                selectRows={(ev) => selectedRows(ev)}
                reportsData={reportsData}
            />
        </>
    )
}

export default withRouter(
    injectIntl(
        connect(null, snackbarRedux.actions)(BankTransferReportsPage)
    )
)
