/* eslint-disable no-restricted-imports */

import React, {useEffect, useState} from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { FormattedMessage, injectIntl } from "react-intl"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { he } from "date-fns/esm/locale"
import RefreshIcon from "@material-ui/icons/Refresh"
import { useStyles } from "../../Common/_styles/orderFilterStyles"
import moment from "moment";

function FilterPanel({
  onParamsChangeSave,
  searchParams,
  locationsData,
  departmentsData,
  sendReportsToEmails,
  deliveryTimesData,
  itemsTotal,
  intl
}) {
  const classes = useStyles()
  const [filteredDeliveryTimes, setFilteredDeliveryTimes] = useState();
  useEffect(() => {
    const data = deliveryTimesData;
    const out = Object.values(
        data.reduce( (c, e) => {
          if (!c[moment('2020-10-10 ' + e.From).format("HH:mm").toString()]) c[moment('2020-10-10 ' + e.From).format("HH:mm").toString()] = e;
          return c;
        }, {})
    );
    setFilteredDeliveryTimes(out);
  }, [deliveryTimesData])

  const handleSearchParamChange = name => data => {
    let value
    if (data.target) {
      value = data.target.value
    } else {
      value = data
    }
    onParamsChangeSave(
      name === "location"
       ? { [name]: value, department: "" }
       : (name == "mealType"
          ? { [name]: value, location: "", department: "" }
          : { [name]: value })
    )
  }

  useEffect(() => {
    handleSearchParamChange("deliveryTime", {
      target: {
        value: ''
      }
    })
  }, [searchParams.location])

  function handleRefresh(event) {
    event.preventDefault()
    onParamsChangeSave(searchParams)
  }

  return (
    <>
      <form
        className={classes.root}
        onSubmit={handleRefresh}
        autoComplete="off"
      >
        <div className={classes.textFieldsWrapper}>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
              <DatePicker
                autoOk
                format="dd/MM/yyyy"
                value={searchParams.date}
                onChange={handleSearchParamChange("date")}
                label={intl.formatMessage({
                  id: "DATE_FIELD.LABEL"
                })}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <TextField
              id="standard-search"
              label={intl.formatMessage({
                id: "SEARCH_FIELD.LABEL"
              })}
              className={classes.nestedTextField}
              value={searchParams.text}
              onChange={handleSearchParamChange("text")}
            />
          </div>
        </div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="mealType">
            <FormattedMessage id="ORDER_ITEMS.TYPE_FIELD.LABEL" />
          </InputLabel>
          <Select
            value={searchParams.mealType}
            onChange={handleSearchParamChange("mealType")}
            inputProps={{
              name: "mealType",
              id: "mealType"
            }}
          >
            <MenuItem value={1}>
              <FormattedMessage id="MEAL_TYPE.VALUE.BREAKFAST" />
            </MenuItem>
            <MenuItem value={2}>
              <FormattedMessage id="MEAL_TYPE.VALUE.LUNCH" />
            </MenuItem>
            <MenuItem value={3}>
              <FormattedMessage id="MEAL_TYPE.VALUE.DINNER" />
            </MenuItem>
            <MenuItem value={4}>
              <FormattedMessage id="MEAL_TYPE.VALUE.STORE" />
            </MenuItem>
          </Select>
        </FormControl>
        <div className={classes.textFieldsWrapper}>
          {
              locationsData.filteredData?.length > 0 && (
                  <div>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="location">
                        <FormattedMessage id="FILTER.LOCATION_FIELD.LABEL" />
                      </InputLabel>
                      <Select
                          value={searchParams.location}
                          onChange={event => {
                            handleSearchParamChange("location")(event)
                          }}
                          inputProps={{
                            name: "location",
                            id: "location"
                          }}
                      >
                        <MenuItem value="">
                          <FormattedMessage id="FILTER.COMMON.ALL" />
                        </MenuItem>
                        {locationsData.filteredData &&
                            locationsData.filteredData.map(location => (
                                <MenuItem key={location.Name} value={location}>
                                  {location.Name}
                                </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </div>
              )
          }
          {
              deliveryTimesData?.length > 1 && (
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="deliveryTimeValue">
                      <FormattedMessage id="FILTER.DELIVERY_TIME_FIELD.LABEL" />
                    </InputLabel>
                    <Select
                        value={searchParams.deliveryTimeValue}
                        onChange={event => {
                          if (event.target.value) {
                            onParamsChangeSave({
                              deliveryTime: deliveryTimesData.filter(item =>
                                  moment('2020-10-10 ' + item.From).format("HH:mm") === event.target.value).map(i => i.Id).join(','),
                              deliveryTimeValue: event.target.value
                            })
                          } else {
                            onParamsChangeSave({
                              deliveryTime: '',
                              deliveryTimeValue: ''
                            })
                          }
                        }}
                        inputProps={{
                          name: "deliveryTimeValue",
                          id: "deliveryTimeValue"
                        }}
                        disabled={!searchParams.location}
                    >
                      <MenuItem value="">
                        <FormattedMessage id="FILTER.COMMON.ALL" />
                      </MenuItem>
                      {filteredDeliveryTimes &&
                          filteredDeliveryTimes.map(deliveryTime => (
                              <MenuItem key={deliveryTime.Name} value={moment('2020-10-10 ' + deliveryTime.From).format("HH:mm").toString()}>
                                {moment('2020-10-10 ' + deliveryTime.From).format("HH:mm")}
                              </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
              )
          }

          <div>
            <FormControl
              className={classes.formControl}
              disabled={!searchParams.location.CustomerID}
            >
              <InputLabel htmlFor="department">
                <FormattedMessage id="FILTER.DEPARTMENT_FIELD.LABEL" />
              </InputLabel>
              <Select
                value={searchParams.department}
                onChange={handleSearchParamChange("department")}
                inputProps={{
                  name: "department",
                  id: "department"
                }}
              >
                <MenuItem value="">
                  <FormattedMessage id="FILTER.COMMON.ALL" />
                </MenuItem>
                {departmentsData.data &&
                  departmentsData.data.map(department => (
                    <MenuItem
                      key={department.DepartmentID}
                      value={department.DepartmentID}
                    >
                      {department.DepartmentName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          <div>
            <Button
              id="send_reports"
              type="button"
              variant="contained"
              color="secondary"
              size="large"
              dir="ltr"
              onClick={() => sendReportsToEmails(searchParams.date)}
              disabled={!searchParams.date}
              className={`font-weight-bold my-3 ml-10`}
            >
              <span>
                <FormattedMessage id="ORDER_ITEMS.SEND_REPORTS" />
              </span>
            </Button>
          </div>
          <div
            className={`font-weight-bold ml-10 d-flex justify-content-center`}
          >
            {itemsTotal !== 0 && (
              <>
                <FormattedMessage id="FILTER.ITEMS_TOTAL" /> {itemsTotal}
              </>
            )}
          </div>
        </div>
        <Button
          id="search_params_submit"
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          dir="ltr"
          disabled={!searchParams.mealType || !searchParams.date}
          className={`font-weight-bold my-3 ml-10`}
        >
          <RefreshIcon />
        </Button>
      </form>
    </>
  )
}

export default injectIntl(FilterPanel)
