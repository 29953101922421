/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useRef, useState } from "react"
import { withRouter } from "react-router-dom"
import FilterPanel from "./FilterPanel"
import { FormattedMessage, injectIntl } from "react-intl"
import moment from "moment"
import OrdersList from "./OrdersList"
import { renderStatus } from "../../Common/itemStatusTranslation"
import ExportButton from "../../Reports/ExportButton"
import { Button } from "@material-ui/core"
import { getLocations } from "../_axios/supplierCrud"
import { getDepartments } from "../../Customers/_axios/customerCrud"
import { renderBoolean } from "./OrdersList"
import {
    markOrderPrinted,
    markOrderReady,
    getOrders,
    sendReportsToEmails
} from "./_axios/ordersCrud"
import StickerWrapper from "./StickerWrapper"
import { useReactToPrint } from "react-to-print"
import { getMealTypeText } from "../../Common/mealTypesTranslation"
import { formatDateForApi, formatDateTime } from "../../Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"

function OrdersPanel({ intl, isActiveTab, selectedSupplierID, ...props }) {
    const dispatch = useDispatch()
    const [sortBy, setSortBy] = useState("");
    const [orderBy, setOrderBy] = useState("");
    const [ordersData, setOrdersData] = useState({
        data: { Date: null, Orders: [] },
        isLoading: false
    })
    const [ordersLocationIdsData, setOrdersLocationIdsData] = useState([]);
    const [exportFileName, setExportFileName] = useState("")
    const [searchParams, setSearchParams] = useState({})
    const [locationsData, setLocationsData] = useState({
        data: [],
        filteredData: [],
        isLoading: true,
        notRequested: true
    })
    const [deliveryTimesData, setDeliveryTimesData] = useState({
        data: [],
        filteredData: [],
        isLoading: true,
        notRequested: true
    })
    const [departmentsData, setDepartmentsData] = useState({
        data: [],
        isLoading: false,
        notRequested: false
    })
    const stickerRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => stickerRef.current,
        onAfterPrint: () => {
            const ids = printData.Orders.map(el => el.OrderItemID)
            markOrderPrinted(ids).then(({ data }) => {
                setOrdersData({
                    data: {
                        ...ordersData.data,
                        Orders: ordersData.data.Orders.map(el => ({
                            ...el,
                            IsPrinted: ids.includes(el.OrderItemID)
                                ? true
                                : el.IsPrinted
                        }))
                    },
                    isLoading: false
                })
                setPrintData({ Date: null, Orders: [] })
            });
        }
    })

    const [printData, setPrintData] = useState({ Date: null, Orders: [] })
    function handleRequestSort(property) {
        const isDesc = sortBy === property && orderBy === "desc";
        setOrderBy(isDesc ? "asc" : "desc");
        setSortBy(property);
    }

    function handleSearchParamsSave(changes) {
        const newParams = {
            ...searchParams,
            ...changes,
            isDateToday: moment(changes.date).isSame(new Date(), "day")
        }
        setSearchParams(newParams)
    }

    function fetchOrders(cancelToken) {
        const { mealType, date, location, department, text, deliveryTime, deliveryTimeValue } = searchParams

        const params = {
            tab: "orders",
            mealType,
            deliveryTime,
            deliveryTimeValue,
            date: formatDateForApi(date),
            location: location.LocationID || "",
            department,
            text
        }

        localStorage.setItem("mealType", mealType)

        props.history.push({
            pathname: `/supplier/${selectedSupplierID}`,
            search: "?" + new URLSearchParams(params).toString()
        })

        setOrdersData({ ...ordersData, isLoading: true })
        getOrders(
            mealType,
            selectedSupplierID,
            params.date,
            params.location,
            department,
            deliveryTime,
            cancelToken.token
        )
            .then(({ data }) => {
                setOrdersData({
                    data: {
                        ...data,
                        Orders: data.Orders.filter(
                            el =>
                                (el.UserName.toLowerCase().includes(text.toLowerCase()) || el.ItemName.toLowerCase().includes(text.toLowerCase()))
                                && (searchParams.isDateToday
                                    ? (el.Status !== 2 ||
                                        moment(el.CloseCancelTime).isBefore(el.StatusUpdated)) &&
                                    el.Status !== 3
                                    : ![2, 3].includes(el.Status))
                        ).map(el => {
                            el.DeliveryTimeFrom = el.DeliveryTime?.From;
                            return el;
                        })
                    },
                    isLoading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_ORDERS_DATA"
                    })
                )
            )
    }

    function handleMarkAllReady() {
        const ids = ordersData.data.Orders.map(el => el.OrderItemID)
        const successIds = []

        const promises = []
        ids.forEach(el => promises.push(markOrderReady(el)))
        async function sendRequests() {
            const result = await Promise.allSettled(promises)
            result.forEach((el, index) => {
                if (el.status === "fulfilled") {
                    successIds.push(ids[index])
                }
            })

            setOrdersData({
                data: {
                    ...ordersData.data,
                    Orders: ordersData.data.Orders.map(el => ({
                        ...el,
                        IsReady: successIds.includes(el.OrderItemID) ? true : el.IsReady
                    }))
                },
                isLoading: false
            })
        }
        sendRequests()
    }

    function handleMarkReady(id) {
        markOrderReady(id)
            .then(() => {
                const orders = [...ordersData.data.Orders]
                const changedItem = orders.find(el => el.OrderItemID === id)
                changedItem.IsReady = true
                setOrdersData({
                    data: { ...ordersData.data, Orders: orders },
                    isLoading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_UPDATE_ORDER_DATA"
                    })
                )
            )
    }

    function handleSetReportsToEmails(date) {
        sendReportsToEmails(selectedSupplierID, date).catch(error =>
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                    id: "API.ERROR.FAILED_TO_SEND_REPORTS_TO_EMAILS"
                })
            )
        )
    }

    function processPrintData(orders) {
        const stickerData = []
        const itemIndexes = {}
        orders.forEach(order => {
            itemIndexes[order.LocationID] = (itemIndexes[order.LocationID] || 0) + 1
            const selectedOptions = order.OptionGroups.map(
                optionGroup => optionGroup.SelectedOptios
            ).join(",")
            for (let i = 0; i < order.StrikerNumber; i++) {
                stickerData.push({
                    ...order,
                    StickerIndex: `${i + 1}/${order.StrikerNumber}`,
                    ItemIndex: `${itemIndexes[order.LocationID]}/${ordersData.data.CountByLocation[order.LocationID]
                        }`,
                    SelectedOptions: selectedOptions,
                    Quantity: order.Quantity
                })
            }
        })
        return stickerData
    }

    function handlePrintOrder(id) {
        setPrintData({
            ...ordersData,
            Orders: processPrintData([
                ordersData.data.Orders.find(el => el.OrderItemID === id)
            ])
        })
    }

    function handlePrintAllOrders() {
        const sortedOrders = orderBy
            ? stableSort(ordersData.data.Orders, getSorting(orderBy, sortBy))
            : ordersData.data.Orders;

        setPrintData({
            ...ordersData,
            Orders: processPrintData(sortedOrders)
        });
    }
    function getSorting(orderBy, sortBy) {
        return orderBy === "desc"
            ? (a, b) => -descendingComparator(a, b, sortBy)
            : (a, b) => descendingComparator(a, b, sortBy);
    }

    function descendingComparator(a, b, sortBy) {
        if (a[sortBy] < b[sortBy]) {
            return -1;
        }
        if (a[sortBy] > b[sortBy]) {
            return 1;
        }
        return 0;
    }

    function stableSort(array, compare) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = compare(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function fetchDepartments(id) {
        setDepartmentsData({ ...departmentsData, isLoading: true })
        getDepartments(id)
            .then(({ data }) => {
                setDepartmentsData({
                    data: data,
                    isLoading: false,
                    notRequested: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_DEPARTMENTS"
                    })
                )
            )
    }

    function fetchLocations(cancelToken, callback) {
        setLocationsData({ ...locationsData, isLoading: true })
        getLocations(selectedSupplierID, true, cancelToken.token)
            .then(({ data }) => {
                setLocationsData({
                    data: data,
                    filteredData: data,
                    isLoading: false,
                    notRequested: false
                })
                if (callback) {
                    callback(data);
                }
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_LOCATIONS"
                    })
                )
            )
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        fetchLocations(cancelToken, (data) => {
            fetchLocationsByMealType(data)
        })
        return () => cancelToken.cancel()
    }, [selectedSupplierID, searchParams.mealType, searchParams.date])

    useEffect(() => {
        if (searchParams.location) {
            const currentLocation = locationsData.data.find(
                location => location.LocationID === searchParams.location?.LocationID
            );
            if (currentLocation) {
                const mealTypeItem =
                    currentLocation.MealsInfo.find(i => +i.MealType === +searchParams.mealType);
                if (mealTypeItem) {
                    setDeliveryTimesData({
                        ...deliveryTimesData,
                        data: mealTypeItem.MealDeliveryTimes
                    })
                    setSearchParams({
                        ...searchParams,
                        deliveryTime: mealTypeItem.MealDeliveryTimes?.length === 1 ?
                            mealTypeItem.MealDeliveryTimes[0].Id : ''
                    });
                } else {
                    setDeliveryTimesData({
                        ...deliveryTimesData,
                        data: []
                    })
                    setSearchParams({
                        ...searchParams,
                        deliveryTime: ''
                    });
                }
            } else {
                setDeliveryTimesData({
                    ...deliveryTimesData,
                    data: []
                })
                setSearchParams({
                    ...searchParams,
                    deliveryTime: ''
                });
            }
        } else {
            setDeliveryTimesData({
                ...deliveryTimesData,
                data: []
            })
            setSearchParams({
                ...searchParams,
                deliveryTime: ''
            });
        }

    }, [searchParams.location])

    useEffect(() => {
        if (!locationsData.notRequested) {
            const urlParams = new URLSearchParams(window.location.search)

            const areParamsPresent = urlParams.get("tab") === "orders"

            let initialParams = {}
            if (areParamsPresent) {
                initialParams = {
                    mealType:
                        +urlParams.get("mealType") || localStorage.getItem("mealType") || 2,
                    date: urlParams.get("date")
                        ? new Date(urlParams.get("date"))
                        : new Date(),
                    location:
                        locationsData.data.find(
                            location => location.LocationID === urlParams.get("location")
                        ) || "",
                    department: urlParams.get("department") || "",
                    text: urlParams.get("text") || "",
                    deliveryTime: urlParams.get("deliveryTime") || "",
                    deliveryTimeValue: urlParams.get('deliveryTimeValue') || '',
                }
                initialParams.isDateToday = moment(initialParams.date).isSame(
                    new Date(),
                    "day"
                )
            } else {
                initialParams = {
                    mealType: localStorage.getItem("mealType") || 2,
                    date: new Date(),
                    isDateToday: true,
                    location: locationsData.data.length === 1 ? locationsData.data[0].LocationID : '',
                    department: "",
                    text: "",
                    deliveryTime: '',
                    deliveryTimeValue: '',
                }
            }

            if (initialParams.department) {
                setDepartmentsData({ ...departmentsData, notRequested: true })
            }
            setSearchParams(initialParams)
        }
    }, [locationsData.notRequested])



    useEffect(() => {
        if (
            isActiveTab &&
            searchParams.mealType &&
            searchParams.date &&
            !departmentsData.notRequested
        ) {
            const locationName = searchParams.location
                ? intl.formatMessage({
                    id: "ORDERS_TABLE.EXPORT_FILE_LOCATION"
                }) +
                "_" +
                searchParams.location.Name +
                "_"
                : ""

            const currentDepartment = departmentsData.data.find(
                department => department.DepartmentID === searchParams.department
            )
            const departmentName = currentDepartment
                ? intl.formatMessage({
                    id: "ORDERS_TABLE.EXPORT_FILE_DEPARTMENT"
                }) +
                "_" +
                currentDepartment.DepartmentName +
                "_"
                : ""

            setExportFileName(
                `${intl.formatMessage({
                    id: "ORDERS_TABLE.EXPORT_FILE_NAME"
                })}_${locationName}${departmentName}${intl.formatMessage({
                    id: "ORDERS_TABLE.EXPORT_FILE_MEAL"
                })}_${getMealTypeText(intl, searchParams.mealType) || ""}_${moment(
                    searchParams.date
                ).format("DD-MM-YYYY")}`
            )

            const cancelToken = axios.CancelToken.source()
            const delay = setTimeout(() => fetchOrders(cancelToken), 300)
            const interval = setInterval(() => fetchOrders(cancelToken), 600000)
            return () => {
                cancelToken.cancel()
                clearTimeout(delay)
                clearInterval(interval)
            }
        }
    }, [searchParams, departmentsData.notRequested, isActiveTab])

    useEffect(() => {
        if (searchParams.location && searchParams.location.CustomerID) {
            fetchDepartments(searchParams.location.CustomerID)
        }
    }, [searchParams.location])

    function fetchLocationsByMealType(datalocations) {
        const { mealType, date, location, department, text, deliveryTime } = searchParams

        const params = {
            tab: "orders",
            mealType,
            date: formatDateForApi(date),
            location: "",
            department: "",
            text,
            deliveryTime
        }
        getOrders(
            mealType,
            selectedSupplierID,
            params.date,
            params.location,
            department,
            deliveryTime,
            axios.CancelToken.source().token
        )
            .then(({ data }) => {
                const locationIds = data.Orders.map(o => o.LocationID);
                const locations = data.Orders.filter(it => it.Location).map(t => t.Location);
                const locationObj = {};
                locations.forEach(loc => {
                    locationObj[loc.LocationID] = loc;
                })
                setLocationsData({ ...locationsData, filteredData: Object.keys(locationObj).map(y => locationObj[y]) })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_ORDERS_DATA"
                    })
                )
            )
    }


    useEffect(() => {
        handleSearchParamsSave({
            location: {},
        })
    }, [selectedSupplierID, searchParams.mealType, searchParams.date])

    useEffect(() => {
        if (printData.Orders.length !== 0) {
            handlePrint()
        }
    }, [printData])

    function getExportData() {
        const optionGroups = []
        ordersData.data.Orders.forEach(order =>
            order.OptionGroups.forEach(optionGroup => {
                if (!optionGroups.find(el => el.GroupName === optionGroup.GroupName)) {
                    optionGroups.push(optionGroup)
                }
            })
        )
        return ordersData.data.Orders.map(order => {
            const formattedOrder = {}
            formattedOrder[
                intl.formatMessage({
                    id: "ORDERS_TABLE.LABELS.LOCATION_NAME"
                })
            ] = order.Location.Name
            formattedOrder[
                intl.formatMessage({
                    id: "ORDERS_TABLE.LABELS.DEPARTMENT_NAME"
                })
            ] = order.DepartmentName
            formattedOrder[
                intl.formatMessage({
                    id: "ORDERS_TABLE.LABELS.USER_NAME"
                })
            ] = order.UserName + ((order.CreatorName &&
                order.CreatorName !== order.FullName) ? '(' + order.CreatorName + ')' : '')
            formattedOrder[
                intl.formatMessage({
                    id: "ORDERS_TABLE.LABELS.ITEM_NAME"
                })
            ] = order.ItemName
            formattedOrder[
                intl.formatMessage({
                    id: "ORDERS_TABLE.LABELS.ORDER_QUANTITY"
                })
            ] = order.Quantity
            formattedOrder[
                intl.formatMessage({
                    id: "ORDERS_TABLE.LABELS.ORDER_DATE"
                })
            ] = formatDateTime(order.OrderCreated)
            optionGroups.forEach(optionGroup => {
                const optionData = order.OptionGroups.find(
                    el => el.GroupName === optionGroup.GroupName
                )
                formattedOrder[optionGroup.GroupName] = optionData
                    ? optionData.SelectedOptios
                    : ""
            })
            formattedOrder[
                intl.formatMessage({
                    id: "ORDERS_TABLE.LABELS.COMMENTS"
                })
            ] = order.Notes
            formattedOrder[
                intl.formatMessage({
                    id: "ORDERS_TABLE.LABELS.IS_CLOSED"
                })
            ] = renderBoolean(intl, order.IsClose)
            formattedOrder[
                intl.formatMessage({
                    id: "ORDERS_TABLE.LABELS.IS_PRINTED"
                })
            ] = renderBoolean(intl, order.IsPrinted)
            formattedOrder[
                intl.formatMessage({
                    id: "ORDERS_TABLE.LABELS.IS_READY"
                })
            ] = renderBoolean(intl, order.IsReady)
            if (searchParams.isDateToday) {
                formattedOrder[
                    intl.formatMessage({
                        id: "ORDERS_TABLE.LABELS.STATUS"
                    })
                ] = renderStatus(
                    intl,
                    order.Status,
                    moment()
                        .startOf("day")
                        .subtract(6, "hours")
                        .isBefore(order.StatusUpdated)
                )
            }
            formattedOrder[
                intl.formatMessage({
                    id: "ORDERS_TABLE.LABELS.DELIVERY_TIME"
                })
            ] = `${order.DeliveryTime?.From}`
            return formattedOrder
        })
    }

    return (
        <>
            {searchParams.mealType && (
                <FilterPanel
                    onParamsChangeSave={handleSearchParamsSave}
                    locationsData={locationsData}
                    deliveryTimesData={deliveryTimesData.data}
                    departmentsData={departmentsData}
                    searchParams={searchParams}
                    sendReportsToEmails={handleSetReportsToEmails}
                    itemsTotal={ordersData.data.Orders.reduce(
                        (sum, order) => (![2, 3].includes(order.Status) ? sum + order.Quantity : sum),
                        0
                    )}
                />
            )}
            <StickerWrapper data={printData} ref={stickerRef} />
            <div className="mt-3">
                <Button
                    color="secondary"
                    variant="contained"
                    type="button"
                    size="large"
                    className={`font-weight-bold mt-3 mb-3`}
                    onClick={() => handlePrintAllOrders(sortBy, orderBy)}
                >
                    <FormattedMessage id="ORDERS_TABLE.LABELS.PRINT_ALL" />
                </Button>

                <OrdersList
                    ordersData={ordersData}
                    markReady={handleMarkReady}
                    handlePrint={handlePrintOrder}
                    isDateToday={searchParams.isDateToday}
                    sortBy={sortBy}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                {ordersData.data.Orders.length !== 0 && (
                    <div className="d-flex justify-content-around">
                        {moment(ordersData.data.Date).isSame(new Date(), "day") && (
                            <Button
                                color="secondary"
                                variant="contained"
                                type="button"
                                size="large"
                                className={`font-weight-bold mt-3 mb-3`}
                                onClick={handleMarkAllReady}
                            >
                                <FormattedMessage id="ORDERS_TABLE.BUTTONS.MARK_ALL_READY" />
                            </Button>
                        )}

                        <Button
                            color="secondary"
                            variant="contained"
                            type="button"
                            size="large"
                            className={`font-weight-bold mt-3 mb-3`}
                            onClick={() => handlePrintAllOrders(sortBy, orderBy)}
                        >
                            <FormattedMessage id="ORDERS_TABLE.BUTTONS.MARK_ALL_PRINTED" />
                        </Button>

                        <ExportButton
                            exportData={getExportData()}
                            fileName={exportFileName}
                        />
                    </div>
                )}
            </div>
        </>
    )
}
export default withRouter(injectIntl(OrdersPanel))
