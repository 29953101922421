/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import SupplierRangeFilterPanel from "../modules/Reports/POSRequest/SupplierRangeFilterPanel"
import ListsWrapper from "../modules/Reports/ListsWrapper"
import { getSuppliers } from "../modules/Suppliers/_axios/supplierCrud"
import {FormattedMessage, injectIntl} from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import { getPOSDetailReports, getPOSDetailReportResend, sendLogs } from "../modules/Reports/_axios/reportsCrud"
import {  } from "../modules/Reports/_axios/reportsCrud"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import OrderItemsList from "../modules/Reports/POSRequest/OrderItemList"
import { useStyles } from "../modules/Common/_styles/listWrapperStyles"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import { Button } from "@material-ui/core";
import ConfirmationDialog from "../modules/Common/ConfirmationDialog.js"
import {getMealTypeText} from "../modules/Common/mealTypesTranslation"

function POSRequestLogReportsPage({ userRole, ownSupplierID, intl, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false,
    notRequested: true
  })
  const [exportFileName, setExportFileName] = useState("")
  const [showConfirmationDialog, setShowConfirmationDialog] = useState("")
  const [resendAllLoading, setResendAllLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({})
  const [supplierData, setSupplierData] = useState({
    data: [],
    isLoading: true
  })
  const today = new Date();
  const isUserAdmin = userRole === "Admin" || userRole === "Owner"

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function reload() {
    const cancelToken = axios.CancelToken.source()
    fetchReports(cancelToken)
  }

  function fetchReports(cancelToken) {
    const { supplier, date, status } = searchParams

    const params = {
      date: formatDateForApi(date),
      supplier: supplier.SupplierID || '',
      status
    }

    props.history.push({
      pathname: "/reports-pos-request-log",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getPOSDetailReports(
      supplier.SupplierID || '',
      params.date,
      params.status,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function fetchSuppliers(cancelToken) {
    getSuppliers(cancelToken.token)
      .then(({ data }) => {
        setSupplierData({ data: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchSuppliers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!supplierData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const date = urlParams.get("date")
        ? new Date(urlParams.get("date"))
        : new Date()
      const supplier = isUserAdmin
          ? urlParams.get("supplier")
              ? supplierData.data.find(
                  supplier => supplier.SupplierID === urlParams.get("supplier")
              )
              : {}
          : supplierData.data[0] || {}

      const status = urlParams.get("status") || ''

      setSearchParams({
        date,
        supplier: supplier || '',
        status
      })
    }
  }, [supplierData])

  useEffect(() => {
    if (
      searchParams.date
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      setExportFileName(
        intl.formatMessage({
          id: "POS.EXPORT_FILE_NAME.POS_DETAIL"
        }) + "_" +
          searchParams.supplier.Name +
          "_" +
          moment(searchParams.date).format("DD-MM-YYYY")
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  function getExportData() {
    const formattedReport = []
    reportsData.reports.forEach(report => {
      const formattedMeal = {}
      formattedMeal[
          intl.formatMessage({
            id: "POS.STATUS"
          })
          ] =
          report.Status === 'true' ?
              intl.formatMessage({
                  id: "POS.SUCCESS"
              })
              :
              intl.formatMessage({
                  id: "POS.ERROR"
              })
      formattedMeal[
          intl.formatMessage({
            id: "POS.DATE"
          })
      ] = moment(report.CreatedDate).locale("he").format("HH:mm:ss")
      formattedMeal[
          intl.formatMessage({
            id: "POS.POS"
          })
          ] = (report.POSType === 1 ? intl.formatMessage({
                id: "POS.BETEAVON"
            }) : intl.formatMessage({
                  id: "POS.OTHER"
              }))
      formattedMeal[
          intl.formatMessage({
            id: "POS.MEAL_TYPE"
          })
          ] = getMealTypeText(intl, report.MealType)
      formattedMeal[
          intl.formatMessage({
            id: "POS.ORDER_NUMBER"
          })
          ] = report.OrderNumber
        formattedMeal[
            intl.formatMessage({
                id: "POS.ORDER_ID"
            })
            ] = report.OrderId
      formattedMeal[
          intl.formatMessage({
            id: "POS.SUPPLIER_NAME"
          })
          ] = report.SupplierName
      formattedMeal[
        intl.formatMessage({
            id: "POS.CUSTOMER_NAME"
        })
        ] = report.CustomerName
        formattedMeal[
            intl.formatMessage({
                id: "POS.USER_NAME"
            })
            ] = report.UserName
        formattedMeal[
            intl.formatMessage({
                id: "POS.ERROR"
            })
            ] = report.Error
        formattedMeal[
            intl.formatMessage({
                id: "POS.RETRY_ERROR_COUNT"
            })
            ] = report.ErrorRetryNumber
        formattedMeal[
            intl.formatMessage({
                id: "POS.RETRY_ERROR_DATE"
            })
            ] = report.ErrorRetryDate ?
                    moment(report.ErrorRetryDate).locale("he").format("DD/MM/YYYY HH:mm:ss") : ''
        formattedMeal[
            intl.formatMessage({
                id: "POS.RETRY_NUMBER"
            })
            ] = report.RetryNumber
        formattedMeal[
            intl.formatMessage({
                id: "POS.LAST_RETRY_DATE"
            })
            ] = report.LastRetryDate ?
                moment(report.LastRetryDate).isSame(today, 'day') ?
                    moment(report.LastRetryDate).locale("he").format("HH:mm:ss") :
                moment(report.LastRetryDate).locale("he").format("DD/MM/YYYY HH:mm:ss") : ''
        formattedReport.push(formattedMeal)
    })
    return formattedReport
  }

  function sendAllLogsHandler(callback) {
    const cancelToken = axios.CancelToken.source()
    const { supplier, date, status } = searchParams
    setResendAllLoading(true)
    sendLogs(
        supplier.SupplierID || '',
        formatDateForApi(date),
        cancelToken.token
    ).then(({}) => {
        callback();
        reload();
    }).catch(error => handleApiError(dispatch, error)).finally(() => {
        setResendAllLoading(false)
    })
  }

  return (
    <>
      {
        searchParams.supplier && (
        <SupplierRangeFilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          supplierData={supplierData}
          initialSearchParams={searchParams}
          hideSupplierField={!isUserAdmin}
        />
      )}
      <div>
          {
              !reportsData.isLoading && reportsData.reports.length > 0 && (
                  <Button variant="contained" component="label"
                          onClick={() => {
                              setShowConfirmationDialog(true);
                          }}>
                      {intl.formatMessage({
                          id: "REPORT.SEND_ALL"
                      })}
                  </Button>
              )
          }
      </div>
      {
        <ListsWrapper
            reportsData={reportsData}
            contents={
              <OrderItemsList data={reportsData} reload={reload} searchParams={searchParams} handleResend={getPOSDetailReportResend}/>
            }
            exportData={getExportData()}
            exportFileName={exportFileName}
        />
      }
      <ConfirmationDialog
          show={showConfirmationDialog}
          dialogTitle={<FormattedMessage id="REPORT.SEND_ALL" />}
          contentText={intl.formatMessage({
            id: "REPORT.SEND_ALL.TEXT"
          })}
          isSubmitting={resendAllLoading}
          onClose={() => setShowConfirmationDialog(false)}
          onSubmit={() => {
            sendAllLogsHandler(() => {
                setShowConfirmationDialog(false);
            });
          }}
      />

    </>
  )
}

function mapStateToProps(state) {
  return {
    ownSupplierID: state.auth.user.SupplierID,
    userRole: state.auth.user.Role
  }
}

export default withRouter(
  injectIntl(connect(mapStateToProps, snackbarRedux.actions)(POSRequestLogReportsPage))
)
