/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import Paper from "@material-ui/core/Paper"
import { withRouter } from "react-router-dom"
import SupplierLocationRangeFilterPanel from "../modules/Reports/SupplierLocationRangeFilterPanel"
import ListsWrapper from "../modules/Reports/ListsWrapper"
import ItemList from "../modules/Reports/ItemOrdersReports/ItemList"
import {
  getLocations,
  getSuppliers
} from "../modules/Suppliers/_axios/supplierCrud"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import { getMealTypeText } from "../modules/Common/mealTypesTranslation"
import { getItemOrderReports } from "../modules/Reports/_axios/reportsCrud"
import { useStyles } from "../modules/Common/_styles/listWrapperStyles"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"

function ItemOrdersReportsPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false,
    notRequested: true
  })
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [supplierData, setSupplierData] = useState({
    data: [],
    isLoading: true
  })
  const [locationsData, setLocationsData] = useState({
    data: [],
    isLoading: true,
    selectedSupplierID: null,
    notRequested: true
  })

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function processReports(data) {
    const processedData = []

    data.Data.forEach(item => {
      const meal = processedData.find(el => el.MealType === item.MealType)
      const newItem = {
        ...item,
        QuantityPercentage: (item.Quantity / item.ResultQuantity) * 100
      }
      if (!meal) {
        processedData.push({
          MealType: newItem.MealType,
          Items: [newItem]
        })
      } else {
        meal.Items = [...meal.Items, newItem]
      }
    })

    return processedData
  }

  function fetchReports(cancelToken) {
    const { supplier, location, from, to } = searchParams

    const params = {
      from: formatDateForApi(from),
      to: formatDateForApi(to),
      location,
      supplier: supplier.SupplierID
    }

    props.history.push({
      pathname: "/reports-item-orders",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getItemOrderReports(
      supplier.SupplierID,
      location || null,
      params.from,
      params.to,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: processReports(data),
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function fetchSuppliers(cancelToken) {
    getSuppliers(cancelToken.token)
      .then(({ data }) => {
        setSupplierData({ data: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
          })
        )
      )
  }

  function fetchLocations(cancelToken) {
    setLocationsData({ ...locationsData, isLoading: true })
    getLocations(locationsData.selectedSupplierID, cancelToken.token)
      .then(({ data }) => {
        setLocationsData({
          data: data,
          isLoading: false,
          selectedSupplierID: locationsData.selectedSupplierID,
          notRequested: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_LOCATIONS"
          })
        )
      )
  }

  function handleUpdateLocations(supplierID) {
    setLocationsData({
      ...locationsData,
      selectedSupplierID: supplierID
    })
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchSuppliers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!supplierData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const from = urlParams.get("from")
        ? new Date(urlParams.get("from"))
        : new Date()
      const to = urlParams.get("to")
        ? new Date(urlParams.get("to"))
        : new Date()
      const location = urlParams.get("location") || ""
      const supplier = urlParams.get("supplier")
        ? supplierData.data.find(
            supplier => supplier.SupplierID === urlParams.get("supplier")
          )
        : {}

      setSearchParams({
        from,
        to,
        location,
        supplier
      })
    }
  }, [supplierData])

  useEffect(() => {
    if (
      searchParams.from &&
      searchParams.to &&
      searchParams.supplier &&
      searchParams.supplier.SupplierID &&
      !locationsData.notRequested
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      const locationName = searchParams.location
        ? intl.formatMessage({
            id: "EXPORT_FILE_LOCATION"
          }) +
          "_" +
          locationsData.data.find(
            location => location.LocationID === searchParams.location
          ).Name +
          "_"
        : ""

      setExportFileName(
        intl.formatMessage({
          id: "EXPORT_FILE_NAME"
        }) +
          "_" +
          intl.formatMessage({
            id: "EXPORT_FILE_SUPPLIER"
          }) +
          "_" +
          searchParams.supplier.Name +
          "_" +
          locationName +
          moment(searchParams.from).format("DD-MM-YYYY") +
          "_-_" +
          moment(searchParams.to).format("DD-MM-YYYY")
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams, locationsData.notRequested])

  useEffect(() => {
    if (locationsData.selectedSupplierID) {
      const cancelToken = axios.CancelToken.source()
      fetchLocations(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [locationsData.selectedSupplierID])

  function getExportData() {
    const quantity = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.Quantity, 0), 0)

    const formattedReport = []
    reportsData.reports.forEach(report => {
      report.Items.forEach(item => {
        const formattedItem = {}
        formattedItem[
          intl.formatMessage({
            id: "REPORT.MEAL_TYPE"
          })
        ] = getMealTypeText(intl, report.MealType)
        formattedItem[
          intl.formatMessage({
            id: "REPORT.ITEM_NAME"
          })
        ] = item.ItemName
        formattedItem[
          intl.formatMessage({
            id: "REPORT.QUANTITY"
          })
        ] = item.Quantity
        formattedItem[
          intl.formatMessage({
            id: "REPORT.QUANTITY_PERCENTAGE"
          })
        ] = item.QuantityPercentage
        formattedReport.push(formattedItem)
      })
    })

    const formattedItem = {}
    formattedItem[
        intl.formatMessage({
          id: "REPORT.MEAL_TYPE"
        })
        ] = intl.formatMessage({
      id: "REPORT.TOTAL"
    })
    formattedItem[
        intl.formatMessage({
          id: "REPORT.QUANTITY"
        })
        ] = quantity
    formattedReport.push(formattedItem)

    return formattedReport
  }

  return (
    <>
      {searchParams.supplier && (
        <SupplierLocationRangeFilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          supplierData={supplierData}
          locationsData={locationsData}
          initialSearchParams={searchParams}
          updateLocations={handleUpdateLocations}
          isLocationMandatory={true}
        />
      )}
      <ListsWrapper
        reportsData={reportsData}
        contents={reportsData.reports.map(meal => (
          <Paper className={classes.paper} key={meal.MealID}>
            <div className={classes.heading}>
              {getMealTypeText(intl, meal.MealType)}
            </div>
            <ItemList itemsData={meal} />
          </Paper>
        ))}
        exportData={getExportData()}
        exportFileName={exportFileName}
      />
    </>
  )
}
export default withRouter(
  injectIntl(connect(null, snackbarRedux.actions)(ItemOrdersReportsPage))
)
