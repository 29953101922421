/* eslint-disable no-restricted-imports */
import React from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import FilledInput from "@material-ui/core/FilledInput"
import InputMask from "react-input-mask"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from "react-intl"
import { useStyles } from "../../Common/_styles/formDialogStyles"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {he} from "date-fns/esm/locale";

const statuses = [
    'TRANSFERRED',
    'CANCELLED'
];

function TransferFormDialog({
  show,
  closeDialog,
  intl,
  variant,
  submitData,
  afterSubmitCloseDialog,
  ids,
}) {
  const dispatch = useDispatch()

  const classes = useStyles()
  const initialValues = {
    referenceNumber: "",
    transferDate: new Date(),
    status: "",
    comments: ""
  }

  function handleSumbit(userID) {
    closeDialog(userID)
  }

  function handleClose() {
    closeDialog(null)
  }

  const requiedValidationSchema = Yup.string().required(
    intl.formatMessage({
      id: "VALIDATION.REQUIRED_FIELD"
    })
  )

  const validationSchema = Yup.object().shape(
    {
      referenceNumber: requiedValidationSchema,
      transferDate: requiedValidationSchema,
      status: requiedValidationSchema
    },
    [
      "referenceNumber",
      "transferDate",
      "status"
    ]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setFieldError, resetForm }) => {
      const {
        referenceNumber,
        transferDate,
        status
      } = values
      setSubmitting(true)
      submitData({
        referenceNumber,
        transferDate,
        status,
        ids,
      })
          .then(() => {
            setSubmitting(false)
            resetForm();
            afterSubmitCloseDialog();
            closeDialog();
          })
          .catch(error => {
            setSubmitting(false)
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_UPDATE_BANK_TRANSFER"
                })
            )
          })

    }
  })

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span style={{ color: "#F018A6" }}>{formik.errors[fieldName]}</span>
    ) : null
  }

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="ADMIN_BANKS.TRANSFER_FORM.TITLE" />
          </h3>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogContent>


            <TextField
                id="referenceNumber-name"
                name="referenceNumber"
                label={intl.formatMessage({
                  id: "ADMIN_BANKS.TRANSFER_FORM.REFERENCE_NUMBER_FIELD.LABEL"
                })}
                className={classes.textField}
                margin="normal"
                variant="filled"
                fullWidth
                {...formik.getFieldProps("referenceNumber")}
                error={checkForError("referenceNumber")}
            />
            {renderErrors("referenceNumber")}

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
              <DatePicker
                  autoOk
                  clearable
                  format="dd/MM/yyyy"
                  value={formik.values.transferDate}
                  onChange={value => {
                    formik.setFieldValue("transferDate", value)
                  }}
                  minDate={new Date()}
                  TextFieldComponent={({ helperText, InputProps, ...props }) => (
                      <TextField
                          id="start-date"
                          label={intl.formatMessage({
                            id: "ADMIN_BANKS.CREATE_FORM.TRANSFER_DATE_FIELD.LABEL"
                          })}
                          className={classes.textField}
                          {...props}
                          margin="normal"
                          variant="filled"
                          fullWidth
                      />
                  )}
              />
            </MuiPickersUtilsProvider>
            
            <FormControl
                variant="filled"
                className={classes.textField}
                fullWidth
                error={checkForError("status")}
            >
              <InputLabel htmlFor="role-select">
                <FormattedMessage id="ADMIN_BANKS.TRANSFER_FORM.STATUS_FIELD.LABEL" />
              </InputLabel>
              <Select
                  {...formik.getFieldProps("status")}
                  input={<FilledInput name="status" id="status-select" />}
              >
                {statuses.map(status => (
                    <MenuItem key={status} value={status}>
                      <FormattedMessage id={`STATUS.${status}`} />
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            {renderErrors("status")}

            <TextField
              id="comments-name"
              name="comments"
              label={intl.formatMessage({
                id: "ADMIN_BANKS.TRANSFER_FORM.COMMENTS_FIELD.LABEL"
              })}
              className={classes.textField}
              margin="normal"
              variant="filled"
              fullWidth
              {...formik.getFieldProps("comments")}
              error={checkForError("comments")}
            />
            {renderErrors("comments")}

          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              type="button"
              size="large"
              onClick={handleClose}
              className={classes.button}
            >
              <FormattedMessage id="ADMIN_BANKS.TRANSFER_FORM.CANCEL_BUTTON" />
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="secondary"
              className={classes.button}
              disabled={formik.isSubmitting}
            >
              <FormattedMessage id="ADMIN_BANKS.TRANSFER_FORM.SUBMIT_BUTTON" />
              {formik.isSubmitting && (
                <span className="ml-1 spinner spinner-white"></span>
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
export default injectIntl(TransferFormDialog)
